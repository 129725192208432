
// prototypes
////////////////////////////////////////////////////////////
if (!Array.IsNotArrayOrEmpty) {
    Array.IsNotArrayOrEmpty = function (obj) {
        return (
            typeof obj === 'undefined'
            || !obj
            || !Array.isArray(obj)
            || obj.length == 0
        )
    };
}


Number.prototype.format2Digits = function () {
    return this.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

String.prototype.format2Digits = function () {
    return parseInt(this).format2Digits();
};


window.downloadFile = function (fileUrl) {
    var a = document.createElement('A');
    a.href = fileUrl;
    a.download = fileUrl.substr(fileUrl.lastIndexOf('/') + 1);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};
import Vue from 'vue'
import App from './App'

import router from './router';
import store from './store';
// import socket from './core/http/socket'
// plugins import
import VueSweetAlert from 'vue-sweetalert';
import VueTippy from 'vue-tippy';
import VueHtml2Canvas from 'vue-html2canvas';
import vSelect from 'vue-select';
import Dropdown from '@/components/UI/Dropdown/index';


import AlertMixing from './mixings/Alerts'
// import VueSocketIO from 'vue-socket.io'
import Notifications from 'vue-notification'

import Acl from './core/acl/index'
import './core/filters/date_filters'
import './core/filters/number'
import PageHeader from './components/Common/PageHeader.vue'
import Modal from './components/Common/Modal.vue'
import Preloader from './components/Common/Preloader.vue'
import FormInput from './components/UI/FormInput.vue'
import FormSelect from './components/UI/FormSelect.vue'

import Config from './Config';

import _ from 'lodash';


// css - dean
// import './scss/seatup_style.scss'
// import 'vue-select/dist/vue-select.css';
// import "@fortawesome/fontawesome-free/css/all.css";

// datatable VueGoodTablePlugin
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

// mixings import
import FileStoragePath from './mixings/FileStoragePath';
import Auth from './mixings/Auth';



// Components
////////////////////////////////////////////////////////////
Vue.component('v-select', vSelect)
Vue.component('PageHeader', PageHeader)
Vue.component('Modal', Modal)
Vue.component('FormInput', FormInput)
Vue.component('FormSelect', FormSelect)
Vue.component('Preloader', Preloader)

// Plugins
////////////////////////////////////////////////////////////
Vue.use(Acl, '')
Vue.use(VueGoodTablePlugin); // datatable VueGoodTablePlugin
Vue.use(VueHtml2Canvas);
Vue.use(VueSweetAlert, { useRejections: false });
Vue.use(VueTippy); //add tippy tooltips
Vue.use(Notifications)
Vue.use(Dropdown)

import VueTimeago from 'vue-timeago'

Vue.use(VueTimeago, {
    name: 'timeago', // component name, `timeago` by default
    locale: 'he-IL',
    locales: {
        'he-IL': [
            "לפני פחות מדקה",
            ["לפני שנייה", "לפני %s שניות"],
            ["לפני דקה", "לפני %s דקות "],
            ["לפני שעה", "לפני %s שעות"],
            ["אתמול", " לפני %s שעות"],
            ["לפני שבוע", "לפני %s שבועות"],
            ["לפני חודש", "לפני %s חודשים"],
            ["לפני שנה", "לפני %s שנים"]
        ]
    }
})

// yonso helpers and globals
//////////////////////////////////////////////////////////////////
import CONTACT_TITLES from './components/Customers/CustomerContactTitles.json';

function titleIdToName(titleId) {

    if (!titleId)
        return planup.$t('contactNoTitle'); //not the pretiest way to call $t

    for (var i = 0; i < CONTACT_TITLES.length; i++) {
        if (CONTACT_TITLES[i].id == titleId)
            return CONTACT_TITLES[i]['name'];
    }
}

function timestampToDateAndTime(d) {


    if (!d)
        return '';

    d = new Date(d);

    return d.getDate() +
        "/" + (d.getMonth() + 1) +
        "/" + d.getFullYear() +
        " " + d.getHours() +
        ":" + d.getMinutes() +
        ":" + d.getSeconds();
}


function niceDate(inputDate) {

    if (!inputDate)
        return '';

    //if it is a date use the other function, cool!
    if (typeof inputDate.getMonth === 'function') {
        return dateToStr(inputDate, 'DD/MM/YYYY', '/')
    }



    let myDate = new Date(inputDate.slice(0, -1));

    return myDate.getDate() + '/' + (myDate.getMonth() + 1) + '/' + myDate.getFullYear();

}

function niceTime(inputDate) {

    if (!inputDate)
        return '';

    //if it is a date use the other function, cool!
    if (typeof inputDate.getMonth === 'function') {
        return inputDate.toLocaleTimeString().slice(0, -3);
    }


    let myDate = new Date(inputDate.slice(0, -1));

    return myDate.getHours() + ':' + (myDate.getMinutes() < 10 ? '0' : '') + myDate.getMinutes();
}

const alphabetArray = Array.from('אבגדהוזחטיכלמנסעפצקרשת')


function hebrewDateDay(date) {

    let hebrewDate = new Intl.DateTimeFormat('he-u-ca-hebrew', { month: 'numeric', day: 'numeric' }).format(date)
    let hebrewDay = hebrewDate.split(' ').at(0)



    if (hebrewDay == 15)
        return `ט"ו ${hebrewDate.split(' ').pop()}`


    if (hebrewDay == 16)
        return `ט"ז ${hebrewDate.split(' ').pop()}`

    let units = hebrewDay % 10;
    let tens = parseInt(hebrewDay / 10);

    if (units == 0)
        return `${alphabetArray[tens + 8] ?? ''}' ${hebrewDate.split(' ').pop()}`


    if (hebrewDay < 10)
        return `${alphabetArray[units - 1] ?? ''}' ${hebrewDate.split(' ').pop()}`


    return `${alphabetArray[tens + 8] ?? ''}"${alphabetArray[units - 1] ?? ''} ${hebrewDate.split(' ').pop()}`



}


import { dateToStr } from "./helpers/date-helper.js";
import { getVatPercent } from '../../shared/vat.js';
import { getVatPercentDecimal } from '../../shared/vat.js';





import { saveAs } from 'file-saver';

const findComponent = (name) => {
    function findComponentInner(vm, name) {
        if (vm.$options.name === name) {
            return vm;
        }
        for (const child of vm.$children) {
            const found = findComponentInner(child, name);
            if (found) return found;
        }
        return null;
    }
    return findComponentInner(planup.$root, name);
};



let closeAllModals = () => {
    var modals = document.querySelectorAll('.modal.show');
    modals.forEach(function (modal) {
        var modalInstance = bootstrap.Modal.getInstance(modal);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
}




let yonsoHelpers = {
    install() {
        Vue.prototype.$window = window; //nice

        


        Vue.prototype.$ = window.$;
        Vue.prototype.$globals = {};
        Vue.prototype.$globals.randomFace = () => `https://randomuser.me/api/portraits/${Math.floor(Math.random() * 99) % 2 ? 'wo' : ''}men/${Math.floor(Math.random() * 99)}.jpg`;
        Vue.prototype.$globals.timestampToDateAndTime = timestampToDateAndTime;
        Vue.prototype.$globals.niceTime = niceTime;
        Vue.prototype.$globals.niceDate = niceDate;
        Vue.prototype.$globals.hebrewDateDay = hebrewDateDay;
        Vue.prototype.$globals.saveAs = saveAs;
        Vue.prototype.$globals.findComponent = findComponent;
        Vue.prototype.$globals.closeAllModals = closeAllModals;
        Vue.prototype.$globals.isMobile = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i);

        Vue.prototype.$globals.console = window.console;

        Vue.prototype.$globals.timeStrToObj = (timeStr) => {

        }

        Vue.prototype.$globals.vatPercent = getVatPercent();
        Vue.prototype.$globals.vatPercentDecimal = getVatPercentDecimal();




        Vue.prototype.$globals.exportCsv = function (filename, tableElement) {

            function exportTableToCSV(filename) {

                var csv = [];

                var rows = tableElement.querySelectorAll("tr");

                for (var i = 0; i < rows.length; i++) {

                    var row = [], cols = rows[i].querySelectorAll("td, th");


                    for (var j = 0; j < cols.length; j++) {


                        //little edit to work with vue good table
                        if (cols[j].innerText.indexOf('Sort table') != -1) {

                            row.push(cols[j].innerText.substring(0, cols[j].innerText.indexOf('Sort table')).replace(/(?:\r\n|\n|\r)/g, ' ').replace(/^\s+|\s+$|\s+(?=\s)/g, ' '));

                        } else {

                            row.push(cols[j].innerText.replace(/(?:\r\n|\n|\r)/g, ' ').replace(/^\s+|\s+$|\s+(?=\s)/g, ' '));

                        }

                    }

                    csv.push(row.join(","));
                }

                // Download CSV file
                downloadCSV(csv.join("\n"), filename);
            }

            function downloadCSV(csv, filename) {
                var csvFile;
                var downloadLink;

                // CSV file
                csvFile = new Blob([csv], { type: "text/csv" });

                // Download link
                downloadLink = document.createElement("a");

                // File name
                downloadLink.download = filename;

                // Create a link to the file
                downloadLink.href = window.URL.createObjectURL(csvFile);

                // Hide download link
                downloadLink.style.display = "none";

                // Add the link to DOM
                document.body.appendChild(downloadLink);

                // Click download link
                downloadLink.click();
            }

            exportTableToCSV(filename);
        }



        Vue.prototype.$globals.config = Config;

        Vue.prototype.$globals.addTimeToDate = (dateObject, timeString) => {


            let d = new Date(dateObject.getTime());
            let hoursAndMinutes = timeString ? timeString.split(':') : [1, 1];  //if no time, set to 1:1 it is 1 hour 1 minute into the day, probably top of daily agenda 
            d.setHours(hoursAndMinutes[0], hoursAndMinutes[1], 0, 0);

            return d;
        }


        Vue.prototype.$globals.compareFunctionForSortCalendarItems = (a, b) => {

            let aTime = a.startDate;
            let bTime = b.startDate;

            if (aTime < bTime) {
                return -1;
            }
            if (aTime > bTime) {
                return 1;
            }

            return 0;
        }


        window.throttle = (func, delay) => {
            let lastCall = 0;
            return function (...args) {
                const now = Date.now();
                if (now - lastCall >= delay) {
                    lastCall = now;
                    return func(...args);
                }
            };
        }

        Vue.prototype.$globals.notifyFormError = throttle(() => {

            planup.$notify({
                type: 'error',
                title: 'שגיאה: השדות שהוזנו אינם תקינים',
                text: 'נא לתקן ולשלוח מחדש',
            });
            window.scrollTo({ top: 0, behavior: 'smooth' });


        }, 750);


        Vue.prototype.$globals.concatContactNames = (customer) => {

            if (!customer?.contacts?.length)
                return '';

            let str = '';
            customer.contacts.forEach(c => str += (c.firstName ? ' ' + c.firstName : '') + (c.lastName ? ' ' + c.lastName : '') + ', ');
            return str.slice(0, -2);

        }


        Vue.directive('numeric-only', {
            bind(el) {
                el.addEventListener('input', () => {
                    el.value = el.value.replace(/\D/g, '');
                });
            }
        });



        Vue.prototype.$globals.screenIsMobile = window.innerWidth < 992;


        Vue.prototype.$globals.clickOnItem = (itemType, itemId) => {


            switch (itemType) {
                case 'event':
                    planup.$root.$emit('hideQuickCalendar');
                    planup.$router.push(`/eventToCustomer/${itemId}`)
                    return;
                case 'meeting':
                case 'appointments':
                    planup.$root.$emit('hideQuickCalendar');
                    planup.$root.$emit('hideQuickCalendar');
                    planup.$router.push(`/meetingToCustomer/${itemId}`)
                    return;
                case 'task':
                    planup.$root.$emit('hideQuickCalendar');
                    return planup.$handlers.tasksHandler.getTask(itemId).then(task => planup.$root.$emit('openTaskModal', task))
                case 'holiday':
                    console.log('hohohohoholiday');
                    break;
            }


        }





        Vue.prototype.$globals.dayOfWeekFromDate = (d) => {

            switch ((new Date(d)).getDay()) {
                case 0:
                    return "א'";
                case 1:
                    return "ב'";
                case 2:
                    return "ג'";
                case 3:
                    return "ד'";
                case 4:
                    return "ה'";
                case 5:
                    return "ו'";
                case 6:
                    return "שבת";
            }

        }

        Vue.prototype.$globals.getLetterImage = name => {


            if (!name)
                return 'https://mit4mit.s3.amazonaws.com/yoni/user.png';

            return `https://mit4mit-2018.s3.amazonaws.com/username_letterimages/${name[0].toUpperCase()}.jpg`;
        }
    }
}
Vue.use(yonsoHelpers);



import ListsHandler from './modules/ListsHandler';
import EventHandler from './modules/EventHandler';
import MeetingHandler from './modules/MeetingHandler';
import CustomerHandler from './modules/CustomerHandler';
import ReportsHandler from './modules/ReportsHandler.js';
import DocumentsHandler from './modules/DocumentsHandler';
import TasksHandler from './modules/TasksHandler';
import LogHandler from './modules/LogHandler';
import CalendarHandler from './modules/CalendarHandler';
import MilestoneHandler from './modules/MilestoneHandler';
import TaxDocumentHandler from './modules/TaxDocumentHandler';
import WhatsappHandler from './modules/WhatsappHandler';
import PlanupMultiMessagesHandler from './modules/PlanupMultiMessagesHandler.js';
import ErrorHandler from './modules/ErrorHandler.js';
import MessageTemplateHandler from './modules/MessageTemplateHandler.js';


let planupHandlers = {
    install() {
        Vue.prototype.$handlers = {};
        Vue.prototype.$handlers.listsHandler = ListsHandler;
        Vue.prototype.$handlers.eventHandler = EventHandler;
        Vue.prototype.$handlers.meetingHandler = MeetingHandler;
        Vue.prototype.$handlers.customerHandler = CustomerHandler;
        Vue.prototype.$handlers.documentsHandler = DocumentsHandler;
        Vue.prototype.$handlers.logHandler = LogHandler;
        Vue.prototype.$handlers.tasksHandler = TasksHandler;
        Vue.prototype.$handlers.calendarHandler = CalendarHandler;
        Vue.prototype.$handlers.milestoneHandler = MilestoneHandler;
        Vue.prototype.$handlers.taxDocumentHandler = TaxDocumentHandler;
        Vue.prototype.$handlers.whatsappHandler = WhatsappHandler;
        Vue.prototype.$handlers.reportsHandler = ReportsHandler;
        Vue.prototype.$handlers.errorHandler = ErrorHandler;
        Vue.prototype.$handlers.planupMultiMessagesHandler = PlanupMultiMessagesHandler;
        Vue.prototype.$handlers.messageTemplatesHandler = MessageTemplateHandler;



    }
}
Vue.use(planupHandlers);


//////////// end yonso globals helpers ////////////////////////////////////






// Mixings
//////////////////////////////////////////////////////////////////
Vue.mixin(AlertMixing);
Vue.mixin(FileStoragePath);
Vue.mixin(Auth);



// The great haxxy v-style, which basically is a <style> element that we can v-if on wo0t
//////////////////////////////////////////////////////////////////
Vue.component('v-style', {
    render: function (createElement) {
        return createElement('style', this.$slots.default)
    }
});

import ToggleButton from 'vue-js-toggle-button'
Vue.use(ToggleButton)




// Init
//////////////////////////////////////////////////////////////////
Vue.config.productionTip = false;
// Vue.prototype.$socket = socket
new Vue({
    render: h => h(App),
    router,
    store,
}).$mount('#app');


// Generic error handler
// Vue.config.errorHandler = function(err, vm, info) {
//     console.log(`Error: ${err.toString()}\nInfo: ${info}`);
// }

