import axiosV2, { apiRequest, apiPost, apiDelete, apiPatch } from '../core/http/axios_api_v2';
import EventHandler from './EventHandler';


// i think i need a way here to push into the state lists for it to listen and rerender ui
// because when i call $store from planup.$store i think it's not working properly


export default {


    async createListItem(name, storeListName, controller, objectToUpdate, fieldToSet, fieldIsAList) {

        return apiPost(controller, { name }).then(newItem => {

            planup.$store.state[storeListName].push(newItem);
            // planup.$store.updateList(storeListName); //not needed cuz push is a vue function

            if (objectToUpdate && fieldToSet) {
                //weird that we don't need vue.set, i guess because the field is declared as the multiselect model
                if (fieldIsAList) {
                    objectToUpdate[fieldToSet].push(newItem);
                    // objectToUpdate[fieldToSet] = deepCopy(objectToUpdate[fieldToSet]); //not needed cuz push is a vue function
                } else {
                    objectToUpdate[fieldToSet] = newItem;
                }

            }


            return newItem;
        })
    },



    async updateListItemName(item, storeListName, controller) {

        apiPost(controller, item).then(response => {

            planup.$store.state[storeListName].find(x => x.id == item.id).name = item.name;
        })

    },


    deleteItem(controller, id, storeListName) {

        planup.$store.state[storeListName].find(x => x.id == id).hidden = true;
        // planup.$store.updateList(storeListName); //not needed because we are updating an object inside the array and not the array location

        apiDelete(`${controller}/${id}`);
    },



    createProduct(name,callback){

       
        apiPost(`/products/bulkSave`, [{name:name}]).then((newListFromServer) => {

            planup.$store.state.products = newListFromServer;

            if (callback){
                let newProduct = newListFromServer.find(p => p.name==name)

                if (newProduct)
                    callback(newProduct);
            }

        })

    },


    // this is for quickSuperEdit
    bulkSave(itemsToSave, storeListName, controller, bulkSaveFilterFunction, callback) {


        let filteredItemsToSave = bulkSaveFilterFunction ? itemsToSave.map(i => bulkSaveFilterFunction(i)) : itemsToSave;

        apiPost(`/${controller}/bulkSave`, filteredItemsToSave).then((newListFromServer) => {

            planup.$store.state[storeListName] = newListFromServer;
            if (callback)
                callback();
        })
    },


    bulkSave_filterOtherVendor(vendor) {

        let filteredVendor = deepCopy(vendor);

        filteredVendor.category = vendor.category.name;

        return filteredVendor;
    },


    filterAfterGetList_OtherVendor(otherVendor) {

        otherVendor.category = { name: otherVendor.category, value: otherVendor.category };

        return otherVendor;

    },



    bulkSave_filterProduct(product) {

        let filteredProduct = deepCopy(product);

        delete filteredProduct.created;


        if (filteredProduct.priceType) {

            filteredProduct.priceType = filteredProduct.priceType.value;
        }

        // console.log(typeof filteredProduct);

        return filteredProduct;
    },


    bulkSave_filterCrewMembers(crewMember) {

        delete crewMember.created;

        return crewMember;
    },

    bulkSave_planupEventStatuses(status) {

        let filteredStatus = deepCopy(status);

        delete filteredStatus.created;

        return filteredStatus;
    },



    getList(controller, callback) {
        axios(controller).then(callback);
    },


    singleSave(entity, controller, callback) {

        apiPost(controller, entity).then((savedEntity) => {
            if (callback)
                callback(savedEntity);
        })
        
    },



    delete(entity,controller,list){

 
        if (entity.id)
            apiDelete(`${controller}/${entity.id}`);

        if (list && list.length){
            list.splice(list.findIndex(e=>e==entity),1);
        }

    },



    formatProductsForMultiselect(){
        window.planup.$store.state.products.map(product => {


            //i now see if we would use setters i would not need this code twice


            product.priceType = planup.$handlers.eventHandler.productPriceTypeOptions.find(x=>x.value == product.priceType);
            if (!product.priceType)
                product.priceType =planup.$handlers.eventHandler.productPriceTypeOptions.find(x=>x.value == 'global');


          });

          return true;
    },










}