export function getVatPercent(date) {   

    if (!date)
        date = new Date();

    if (date < new Date('2025-01-01')) return 17;
    return 18;
}


export function getVatPercentDecimal(date) {

    return getVatPercent(date) / 100;
}



