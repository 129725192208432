import axiosV2, { apiPost } from '../core/http/axios_api_v2';

export default {




    validatePayment(component, payment) {
        
        let paymentErrors = {};

        paymentErrors.sum = !payment.sum ? 'ערך לא תקין' : null;


        switch (payment.paymentType.value) {

            case 1: //cash
                //nothing to validate
                break;

            case 2: //cheque
                paymentErrors.bank = !payment?.bank?.value ? 'שדה חובה' : null;
                paymentErrors.snif = !payment.snif || payment.snif <= 0 ? 'ערך לא תקין' : null;
                paymentErrors.bankAccountNumber = !payment.bankAccountNumber || payment.bankAccountNumber <= 0 ? 'ערך לא תקין' : null;
                paymentErrors.checkNumber = !payment.checkNumber || payment.checkNumber <= 0 ? 'ערך לא תקין' : null;
                paymentErrors.date = !payment.date ? 'שדה חובה' : null;
                break;

            case 3: // credit card
                paymentErrors.tashlumim = !payment.tashlumim || payment.tashlumim <= 0 ? 'ערך לא תקין' : null;
                paymentErrors.creditCardType = !payment?.creditCardType?.value ? 'שדה חובה' : null;
                paymentErrors.creditCardDealType = !payment?.creditCardDealType?.value ? 'שדה חובה' : null;
                paymentErrors.soleketCompany = !payment?.soleketCompany?.value ? 'שדה חובה' : null;
                paymentErrors.date = !payment.date ? 'שדה חובה' : null;
                break;

            case 4: //bank transfer
                paymentErrors.businessBankAccount = !payment?.businessBankAccount ? 'שדה חובה' : null;
                paymentErrors.date = !payment.date ? 'שדה חובה' : null;
                paymentErrors.bank = !payment.bank ? 'שדה חובה' : null;
                paymentErrors.bankAccountNumber = !payment.bankAccountNumber ? 'שדה חובה' : null;
                paymentErrors.snif = !payment.snif ? 'שדה חובה' : null;
                break;

        }


        component.$set(payment, 'errors', paymentErrors);

        //this checks if there is at least one field with an error
        let paymentIsValid = !Object.values(paymentErrors).some(value => value);

        if (!paymentIsValid)
            planup.$globals.notifyFormError();

        return paymentIsValid;

        return errors;

    },




    create(event, taxDocumentType, createTaxDocumentParams, hideSuccessAlert) {

        if (taxDocumentType == 'hatsaatMehir') {
            planup.$handlers.eventHandler.updateSystemStatus(event, 'priceOfferSent');
        }

        if (taxDocumentType == 'hazmana' && !createTaxDocumentParams.cancelOrderId) {
            planup.$handlers.eventHandler.updateSystemStatus(event, 'hazmanaSent');
        }

        return apiPost(`tax-documents/create/${taxDocumentType}`, createTaxDocumentParams, hideSuccessAlert)
    },


    get(taxDocumentId) {
        return axiosV2(`tax-documents/${taxDocumentId}`)
    },

    async getPreviewPdfId(taxDocumentType, createTaxDocumentParams) {

        return (await axiosV2({ method: 'post', url: `tax-documents/preview/${taxDocumentType}`, data: createTaxDocumentParams })).data
    },

    async getNextNumber(type) {
        return (await axiosV2(`tax-documents/nextNumber/${type}`)).data;
    },

    getTaxDocumentTitle(taxDocument) {

        return planup.$store.state.taxDocumentTypes.find(t => taxDocument.type == t.value).name + ' ' + taxDocument.taxDocumentNumber;
    },

    generateLocalTaxDocumentPayment(payments, totalPrice, howMuchWasPaid = 0) {

        let sumOfPayments = 0;
        payments?.map(payments => sumOfPayments += payments.sum)

        return {
            // paymentType:window.planup.$store.state.taxDocumentPaymentTypes[0],
            sum: totalPrice - sumOfPayments - howMuchWasPaid,
            paymentType: {

            }

        }
    },


    goToAddTaxDocumenToCustomer(customer, taxDocumentType) {
        planup.$router.push(`/createTaxDocument?customerId=${customer.id}&type=${taxDocumentType.value}`)
    },



    createGoToFunctionForFindCustomer(taxDocumentType) {

        return (searchResult) => {

            planup.$router.push(`/createTaxDocument?customerId=${searchResult.customerId}&eventId=${searchResult.eventId}&type=${taxDocumentType.value}`)

        }

    },








}