import Vue from 'vue'
import Vuex from 'vuex'
import Config, { getServerName, isPlanup, useMultiSchemaTenancy } from './Config'
// import socket from './core/http/socket'
import { loadProgressBar } from 'axios-progress-bar'
import MobileDetect from 'mobile-detect'
// import PandaCrmModule from './store/panda-crm.store';
// import GuestStore from './store/guest.store';
// import EventStore from './store/event.store';
// import UserStore from './store/user.store';
// import TasksStore from './store/tasks.store';

// import EventItemStore from './store/event-item.store';
// import MeetingStore from './store/meeting.store';
// import AuthStore from './store/auth.store';
// import FileStore from './store/file.store';
import axiosV2, { apiRequest } from './core/http/axios_api_v2';
import axios from './core/http/axios_api_v1';

import EventHandler from './modules/EventHandler';
import CustomerHandler from './modules/CustomerHandler'
import { getVatPercentDecimal } from '../../shared/vat.js';


loadProgressBar({ showSpinner: false })

Vue.use(Vuex);

document.title = getServerName();


// set store sockets.io

//yonso do not connect socket, comment out this line
// Vuex.Store.prototype.$socket = socket

// ---------------------------------------------------------------------------------
const mb = new MobileDetect(window.navigator.userAgent);

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
};

//for old ios devices (ios <= 9.3.*) with session&localStorage bug
const isKiosk = () => {
    try {
        return sessionStorage.getItem('isKiosk') == 'true' ? true : false;
    } catch (e) {
        console.log('failed to access sessionStorage');
        return false;
    }
};

const getLang = () => {
    try {
        return localStorage.getItem('lang');
    } catch (e) {
        console.log('failed to access sessionStorage');
        return 'he';
    }
};



const getMenuState = () => {
    try {

        return localStorage.getItem('menuState')
    } catch (e) {
        console.log('failed to access sessionStorage');
        return 'closed';
    }
};

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    lastInteraction: (Math.floor(Date.now() / 1000)),
    calendarMonth: null,
    access_token: '',
    isLogged: false,
    dashboardInitiated: false,
    userName: '',
    user: {},
    users: [],
    kioskEvent: { guests: [], preset: {} },
    halls: [],
    meetingTypes: {},
    presets: [],
    printAssets: {
        logo: '',
        divider: '',
    },
    timesArray: [],

    isKiosk: (location.href.includes('?kiosk') || isKiosk()),

    equipments: [],
    usedBanners: [],
    tables: [],
    dashboard: null,
    snippetsInitialized: false,

    app: { name: 'PlanUp', banners: [], domain: Config.SETTINGS.APP_URL },
    tenantId: null,
    preferences: {
        lang: (getLang() ? getLang() : 'he'),
        menuState: (getMenuState() == 'closed' ? 'closed' : 'open'),
    },
    pandaNotifications: [],
    axiosQueue: [],
    isTablet: ((mb.phone() !== null && mb.phone() !== "") || (mb.tablet() !== null && mb.tablet() !== "") ? true : false),
    isLoading: false,
    //guestGroups: [],
    //isKiosk:(window.screen.height > window.screen.width),

    logoImageUrl: '',    //it's important to set the properties here for it to listen because vue cant listen to NEW fields created  on the fly wo0t

    contactTitles: [],
    eventClasses: [],
    halls: [],
    eventPlaces: [],
    crewMembers: [],
    customerSources: [],
    meetingPlaces: [],
    documents: [],
    holidays: [],
    planupEventStatuses: [],
    planupMeetingTypes: [],
    taskTypes: [],
    otherVendors: [],
    milestones: [],
    emptyAutocomplete: [],
    dashboardLeads: [],
    dashboardTasks: [],
    businessSettings: {},
    otherVendorCategories: [],
    leadSources: [],
    planupCustomerStatuses: [],
    messageTemplates: [],
    whatsappStatus: '',
    planupSystemStatuses: [
        {
            value: 'pniaInTipul',
            label: 'פניה בטיפול'
        },
        {
            value: 'pniaNotRelevant',
            label: 'פניה לא רלוונטית'
        },
        {
            value: 'priceOfferSent',
            label: 'יצאה הצעת מחיר'
        },
        {
            value: 'hazmanaSent',
            label: 'נשלחה הזמנה'
        },
        {
            value: 'hazmanaSigned',
            label: 'אירוע סגור'
        },
        {
            value: 'hazmanaCanceled',
            label: 'הזמנה מבוטלת'
        },
        {
            value: '',
            label: 'פניה חדשה'
        },
    ],


    reportsIntersectionOperators: [
        {
            name: 'ללא סינון',
            value: 'none'
        },
        {
            name: 'כולל הכל',
            value: 'intersect'
        },
        {
            name: 'כולל לפחות אחד',
            value: 'union'
        },
        {
            name: 'לא כולל',
            value: 'notunion'
        }
    ],

    reportsNumberOperators: [
        {
            name: 'ללא סינון',
            value: 'none'
        },
        {
            name: 'שווה ל',
            value: 'equals'
        },
        {
            name: 'לפחות',
            value: 'atleast'
        },
        {
            name: 'לכל היותר',
            value: 'atmost'
        }
    ],





    creditCardTypes: [

        {
            name: 'ויזה',
            value: 1
        },
        {
            name: 'מאסטרקארד',
            value: 2
        },
        {
            name: 'אמריקן אקספרס',
            value: 3
        },
        {
            name: 'דיסקאבר',
            value: 4
        },
        {
            name: 'דיינרס',
            value: 5
        },
        {
            name: 'JCB',
            value: 6
        },
        {
            name: 'מאסטרו',
            value: 7
        },
        {
            name: 'לאומיקארד',
            value: 8
        },
        {
            name: 'ישראכרט',
            value: 9
        },

    ],


    taxDocumentSoleketCompanies: [
        {
            name: 'ישראכרט',
            value: 1
        },
        {
            name: 'כאל',
            value: 2
        },
        {
            name: 'דיינרס',
            value: 3
        },
        {
            name: 'אמריקן אקספרס',
            value: 4
        },
        //there is no 5 this is not a mistake
        {
            name: 'לאומי כארד',
            value: 6
        },
    ],


    taxDocumentCreditDealTypes: [

        //DO NOT TOUCH THESE MATCH BKMV AND HESHBONIT YERUKA
        {
            name: 'רגיל',
            value: 1
        },
        {
            name: 'תשלומים',
            value: 2
        },
        {
            name: 'קרדיט',
            value: 3
        },
        {
            name: 'חיוב נדחה',
            value: 4
        },
        {
            name: 'אחר',
            value: 5
        }
    ],


    taxDocumentPaymentTypes: [
        // DO NOT CHANGE THIS. VALUES ARE BY BKMV CODES IN D120
        // THEY ALSO MATCH HESHBONIT YERUKA VALUES 1,2,3,4
        {
            name: 'מזומן',
            value: 1
        },
        {
            name: "צ'ק",
            value: 2
        },
        {
            name: 'כרטיס אשראי',
            value: 3
        },
        {
            name: 'העברה בנקאית',
            value: 4
        },
        // {
        //     name: 'תווי קנייה',
        //     value: 5
        // },
        // {
        //     name: 'תלוש החלפה',
        //     value: 6
        // },
        // {
        //     name: 'שטר',
        //     value: 7
        // },
        // {
        //     name: 'הוראת קבע',
        //     value: 8
        // },
        // {
        //     name: 'אחר',
        //     value: 9
        // },


    ],
    banks: [
        {
            name: 'בנק יהב' + ' (4)',
            value: 4
        },
        {
            name: 'בנק הדואר' + ' (9)',
            value: 9
        },
        {
            name: 'בנק לאומי' + ' (10)',
            value: 10
        },
        {
            name: 'בנק דיסקונט' + ' (11)',
            value: 11
        },
        {
            name: 'בנק הפועלים' + ' (12)',
            value: 12
        },
        {
            name: 'בנק אגוד' + ' (13)',
            value: 13
        },
        {
            name: 'בנק אוצר החייל' + ' (14)',
            value: 14
        },
        {
            name: 'בנק מרכנתיל דיסקונט' + ' (17)',
            value: 17
        },
        {
            name: 'בנק מזרחי טפחות' + ' (20)',
            value: 20
        },
        {
            name: 'Citibank' + ' (22)',
            value: 22
        },
        {
            name: 'HSBC Bank' + ' (23)',
            value: 23
        },
        {
            name: 'יובנק' + ' (26)',
            value: 26
        },
        {
            name: 'Barclays Bank PLC' + ' (27)',
            value: 27
        },
        {
            name: 'בנק למסחר' + ' (30)',
            value: 30
        },
        {
            name: 'בנק הבינלאומי' + ' (31)',
            value: 31
        },
        {
            name: 'בנק ערבי ישראלי' + ' (34)',
            value: 34
        },
        {
            name: 'SBI State Bank of India' + ' (39)',
            value: 39
        },
        {
            name: 'בנק מסד' + ' (46)',
            value: 46
        },
        {
            name: 'מרכז סליקה בנקאי' + ' (50)',
            value: 50
        },
        {
            name: 'בנק פועלי אגודת ישראל' + ' (52)',
            value: 52
        },
        {
            name: 'בנק ירושלים' + ' (54)',
            value: 54
        },
        {
            name: 'חסך קופת חסכון לחינוך' + ' (65)',
            value: 65
        },
        {
            name: 'בנק דקסיה ישראל' + ' (68)',
            value: 68
        },
        {
            name: 'בנק ישראל' + ' (99)',
            value: 99
        },

    ],


    taskRecurrenceRepeatTypes: [
        {
            name: 'ימים',
            value: 'days'
        },
        {
            name: 'שבועות',
            value: 'weeks'
        },
        {
            name: 'חודשים',
            value: 'months'
        },
        {
            name: 'שנים',
            value: 'years'
        }
    ],

    taskRecurrenceRepeatCountTypes: [
        {
            name: 'פעמים',
            value: 'times'
        },
        {
            name: 'ימים',
            value: 'days'
        },
        {
            name: 'שבועות',
            value: 'weeks'
        },
        {
            name: 'חודשים',
            value: 'months'
        },
        {
            name: 'שנים',
            value: 'years'
        }
    ],

    taxDocumentTypes: [
        {
            name: 'קבלה',
            value: 'kabala',
            pluralName: 'קבלות',
            number: 400,
        },
        {
            name: 'חשבונית מס',
            value: 'heshbonitMas',
            pluralName: 'חשבוניות מס',
            number: 305
        },
        {
            name: 'חשבונית מס קבלה',
            value: 'heshbonitMasKabala',
            pluralName: 'חשבוניות מס קבלה',
            number: 320
        },
        {
            name: 'הצעת מחיר',
            value: 'hatsaatMehir',
            pluralName: 'הצעות מחיר',
            number: 10
        },
        {
            name: 'הזמנה',
            value: 'hazmana',
            pluralName: 'הזמנות',
            number: 100
        },
        {
            name: 'חשבונית זיכוי',
            value: 'heshbonitZikui',
            pluralName: 'חשבוניות זיכוי',
            number: 330
        },
        {
            name: 'תעודת משלוח',
            value: 'teudatMishloah',
            pluralName: 'תעודות משלוח',
            number: 200
        },
        {
            name: 'חשבון עסקה',
            value: 'heshbonIska',
            pluralName: 'חשבוניות עסקה',
            number: 300
        },
        {
            name: 'הזמנת רכש',
            value: 'hazmanatRehesh',
            pluralName: 'הזמנות רכש',
            number: 500
        },
    ]




    //here we have stuff that helps handle ui updates for stuff that changes globally
    //for example - when a task is added, we need to update ui in multiple places, depending on the page we are on
    //dashboard page has 3 updates, customer page has one or two. router change cleans this list and components that would need updates
    //add themselves to this list when they are mounted



    // EDIT - THIS MOVED TO TASKSHANDLER
    // EDIT - THIS MOVED TO TASKSHANDLER
    // EDIT - THIS MOVED TO TASKSHANDLER

    // functionsToRunIfTaskIsAdded: []

}





//building times array
for (let i = 0; i < 24; i++) {
    for (let j = 0; j < 60; j += 15) {
        state.timesArray.push(`${i}:${j.toString().padStart(2, '0')}`)
    }

}




const mutations = {



    setApp(state, payload) {
        state.app = payload
    },

    setTenant(state, payload) {
        if (payload && typeof payload != "undefined" && payload != "undefined") {
            axiosV2.defaults.headers.common['x-tenant-id'] = payload
            localStorage.setItem('tenantId', payload)
            state.tenantId = payload

            console.debug('Tenant set successfully', payload)
        } else {
            localStorage.removeItem('tenantId')
            console.debug('Tenant deleted from storage successfully')
        }
    },

    setAccessToken(state, payload) {

        if (payload == null) {

            //yonso small "fix", might break logout
            //and might be bad for security if it leaves access token set
            if (state.access_token)
                return;

            payload = getCookie('token')
        }



        state.access_token = payload

        axiosV2.defaults.headers.common['Authorization'] = 'bearer ' + payload;
        axios.defaults.headers.common['Authorization'] = 'bearer ' + payload;

        document.cookie = `token=${payload}; path=/;SameSite=strict; Secure;`;
    },



    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    // FOR OUR LISTS
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////
    //////////////////


    //     async hideFromList(state,payload){
    // debugger;
    //         let itemToHide = state[payload.listName].find(x => x.id == payload.id);

    //         itemToHide.hidden = true;


    //         let indexOfItemToHide = state[payload.listName].indexOf(itemToHide);

    //         state[payload.listName].splice(indexOfItemToHide,1)





    //     }






}

// actions are functions that cause side effects and can involve
// asynchronous operations.
const actions = {

    refreshLogoImage({ commit }) {
        commit('refreshLogoImage')
    },

    restPatch: ({ commit }, config) =>
        new Promise((resolve, reject) => {
            console.log(config);
            axiosV2.patch(config.route, config.data)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),
    // REST API GENERAL ACTIONS
    restPost: ({ commit }, config) =>
        new Promise((resolve, reject) => {

            axiosV2.post(config.route, config.data)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error.data);
                    reject(error.data)
                });

        }),
    restGet: ({ commit }, config) =>
        new Promise((resolve, reject) => {

            axiosV2.get(config.route)
                .then(function (response) {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    restDelete: ({ commit, state }, config) =>
        new Promise((resolve, reject) => {
            axiosV2.delete(config.route + "/" + config.deleteId)
                .then((response) => {
                    try {
                        if (config.callback)
                            config.callback(response.data)
                        console.log(response.data);
                        resolve(response.data)

                    } catch (e) {
                        reject(e)
                    }
                }).catch(function (error) {
                    console.error(error);
                    reject(error)
                });

        }),
    // REST API GENERAL ACTIONS

    getUsers: ({ commit, state }, data) =>
        new Promise((resolve, reject) => {

            const params = new URLSearchParams(data);
            axiosV2.get("users", { params })
                .then(function (response) {
                    state.users = response.data;
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    addSnippets: () => {
        try {

            if (!state.snippetsInitialized && state.hasOwnProperty('app') && state.app.hasOwnProperty('extra') && state.app.extra
                !== null && state.app.extra.hasOwnProperty('snippets')) {
                const snippets = JSON.parse(state.app.extra.snippets);
                if (typeof snippets === "string" && snippets !== "") {
                    $('head').append(snippets)
                    state.snippetsInitialized = true;
                }
            }
        } catch (e) {
            console.log(`failed to parse snippets - ${e.message}`);
        }
    },

    getLoggedUserInfo: ({ commit }, o) =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('users/get-logged-in-user-info')
                .then(function (response) {
                    state.user = response.data
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),
    logoff: ({ commit, state }) =>
        new Promise((resolve, reject) => {

            state.isLogged = false;
            state.dashboardInitiated = false

            document.cookie = `token=; path=/;SameSite=strict; Secure;`;
            axiosV2.post('auth/logout', {}).then((response) => {

                if (state.isKiosk)
                    setTimeout(() => { window.location = '/?kiosk=1'; }, 500)
                else
                    setTimeout(() => { window.location = '/'; }, 500)

                resolve()
            });
            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.login, { method: 'logoff' }, Config.DEFAULT_API_SETTINGS)
                .then((response) => {

                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });

        }),


    makeLogin: ({ commit, state }, o) =>
        new Promise((resolve, reject) => {

            // debugger;


            var $state = state
            // o.method = 'login';
            let url, headers = {}
            if (useMultiSchemaTenancy()) {
                url = 'tenancy/auth/login'
                headers = { 'x-tenant-id': 'default' }
            } else {
                url = 'auth/login'
            }

            axiosV2.post(url, {
                username: o.email,
                password: o.password
            }, { headers }).then(res => {


                // debugger;
                // debugger;


                if (res.data.access_token) {
                    const token = res.data.access_token.toString()
                    const user = res.data.user
                    commit('setAccessToken', token)

                    $state.user = user;
                    $state.isLogged = true

                    // get tenant id from response

                    commit('setTenant', user.tenant)


                    resolve(user);
                } else {
                    commit('setTenant', null)
                    $state.isLogged = false
                    reject('APIV2: invalid login')
                }

            })
                .catch((e) => {
                    state.isLogged = false
                    console.warn('APIV2: login failed - ' + e.toString());
                    reject(e)
                })

        }),

    checkPassword: ({ commit }, password) =>
        new Promise((resolve, reject) => {
            axiosV2.post('auth/check-password', { password })
                .then((response) => {
                    try {
                        if (!!response.data.confirmed == true) {
                            resolve();
                        } else {
                            reject()
                        }
                    } catch (e) {
                        reject()
                        console.warn('failed to process password check - ' + e.toString())
                    }
                })
                .catch(function (e) {
                    reject()
                    console.warn('password check failed - ' + e.toString());
                });

        }),

    getDashboardData: ({ dispatch, state, commit }) =>
        new Promise((resolve, reject) => {



            //quick get holidays here as well
            // axiosV2('misc/holidays').then(response=>
            //     state.holidays = response.data
            // )






            axiosV2.get('appData')
                .then((response) => {


                    // customDebugger();


                    // console.log('got appdata, response is:');
                    // console.log(response.data);



                    let res = response.data






                    try {

                        state.isLogged = true;
                        state.dashboardInitiated = true;
                        state.dashboard = res.app || {};



                        if (res.user) {
                            state.user = res.user;
                            state.userName = state.user.name;
                        }
                        state.users = (res.users ? res.users : state.users);

                        state.halls = (res.halls ? res.halls : []);
                        // state.meetingTypes = (res.meetingTypes ? res.meetingTypes : {});
                        // state.presets = (res.presets ? res.presets : []);






                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        // yonso yalla celebration loading everything into state
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////

                        state.contactTitles = res.contactTitles;
                        state.eventClasses = res.eventClasses;
                        state.halls = res.halls;
                        state.eventPlaces = res.eventPlaces;
                        state.crewMembers = res.crewMembers;
                        // state.customerSources = res.customerSources;
                        state.leadSources = res.leadSources;
                        state.meetingPlaces = res.meetingPlaces;
                        state.planupEventStatuses = res.planupEventStatuses

                            ;
                        state.planupMeetingTypes = res.planupMeetingTypes;
                        state.otherVendors = res.otherVendors;
                        state.messageTemplates = res.messageTemplates;


                        state.documents = res.documents;
                        state.taskTypes = res.taskTypes;


                        state.tenantId = res.tenantId;
                        window.tenant = res.tenantId;

                        state.holidays = res.holidays;

                        state.meetingsToday = res.meetingsToday;
                        state.eventsThisWeek = res.eventsThisWeek;
                        state.dashboardLeads = res.leads;
                        state.tasksToday = res.tasksToday;
                        state.planupCustomerStatuses = res.planupCustomerStatuses;
                        state.products = res.products;

                        state.products.forEach(product => {



                            //i now see if we would use setters i would not need this code twice

                            product.priceType = planup.$handlers.eventHandler.productPriceTypeOptions.find(x => x.value == product.priceType);
                            if (!product.priceType)
                                product.priceType = planup.$handlers.eventHandler.productPriceTypeOptions.find(x => x.value == 'global');
                        })

                        state.emptyAutocomplete = res.emptyAutocomplete;

                        state.otherVendorCategories = res.otherVendorCategories;

                        if (res.businessSettings) {
                            state.businessSettings = res.businessSettings;
                        }


                        if (!state.businessSettings.bankAccounts)
                            state.businessSettings.bankAccounts = [];











                        let otherVendorCategoryTagFunction = (newTag, list, fieldName, item) => {

                            let newOption = { name: newTag, value: newTag };
                            list.push(newOption);
                            item[fieldName] = newOption;
                            axiosV2.post('other-vendor-categories', newOption);

                        }


                        state.fieldsForQuickSuperEditPlanupEventStatuses = [
                            { name: 'name', label: 'שם' },
                            { name: 'cancelsPnia', label: 'האם לבטל את הפניה?', isCheckbox: true, markedText: '' }
                        ];


                        state.fieldsForQuickSuperEditCrewMembers = [
                            { name: 'name', label: 'שם' },
                            { name: 'phone', label: 'טלפון', isNumber: true },
                            { name: 'email', label: 'מייל' },
                            { name: 'color', label: 'צבע', isColor: true },
                            { name: 'pic', isPic: true },
                            { name: 'googleCalendarColorId', label: 'צבע ביומן גוגל', isGoogleCalendarColorId: true }
                        ];


                        state.googleColorIds = [
                            { //0
                                'color': '#FFFFFF',
                                'name': 'צבע ברירת מחדל'
                            },
                            { //1
                                'color': '#A4BDFC',
                                'name': 'לוונדר'
                            },
                            { //2
                                'color': '#7AE7BF',
                                'name': 'דשא'
                            },
                            { //3
                                'color': '#DBADFF',
                                'name': 'ענבים'
                            },
                            { //4
                                'color': '#FF887C',
                                'name': 'פלמינגו'
                            },
                            { //5
                                'color': '#FBD75B',
                                'name': 'בננה'
                            },
                            { //6
                                'color': '#FFB878',
                                'name': 'מנדרינה'
                            },
                            { //7
                                'color': '#46D6DB',
                                'name': 'טווס'
                            },
                            { //8
                                'color': '#E1E1E1',
                                'name': 'גרפיט'
                            },
                            { //9
                                'color': '#5484ED',
                                'name': 'אוכמניות'
                            },
                            { //10
                                'color': '#51B749',
                                'name': 'ריחן'
                            },
                            { //11
                                'color': '#DC2127',
                                'name': 'עגבניה'
                            },

                        ]


                        let blurFunctionForSuperEditPrice = function (product) {



                            if (!product.price)
                                return;


                            window.planup.$swal({
                                title: 'האם המחיר שהזנת כולל מע"מ?',
                                showCancelButton: true,
                                confirmButtonText: 'כן',
                                cancelButtonText: 'לא',
                                confirmButtonColor: '#ab8ce4'
                            }).then((includesVat) => {

                                if (includesVat)
                                    product.price = Math.round((product.price / (1 + getVatPercentDecimal())) * 100) / 100;

                            }).catch(x => { })

                        }

                        state.fieldsForQuickSuperEditProducts = [
                            { name: 'name', label: 'שם' },
                            { name: 'price', label: 'מחיר', isNumber: true, blurFunction: blurFunctionForSuperEditPrice },
                            { name: 'priceType', label: 'תמחור', options: EventHandler.productPriceTypeOptions }
                        ];


                        state.fieldsForQuickSuperEditOtherVendors = [
                            { name: 'name', label: 'שם' },
                            { name: 'phone', label: 'טלפון' },
                            { name: 'contactName', label: 'איש קשר' },
                            { name: 'category', label: 'קטגוריה', options: state.otherVendorCategories, optionsTagFunction: otherVendorCategoryTagFunction }
                        ];



                        state.logoImageUrl = `https://planup-il.s3.eu-central-1.amazonaws.com/${res.tenantId}_logo.jpg?` + Math.random();


                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////
                        /////////////////////////////////////////////////





                        // inbox
                        // state.TasksStore.inbox = res.inbox
                        // event types
                        // state.EventStore.eventTypes = res.eventTypes ? res.eventTypes : []

                        // if (state.user.type == 3 && Array.isArray(res.events)) {

                        //     commit('setClosestEvents', res.events)
                        //     commit('setEvent', res.events.at(-1))

                        // }

                        // if (typeof res.app !== 'undefined') {
                        //     state.app = res.app
                        //     state.app.banners = []
                        //     document.title = state.app.name;

                        //     if (!state.snippetsInitialized) store.dispatch('addSnippets')

                        // } else {
                        //     state.app = {
                        //         name: 'SeatUP'
                        //     }
                        // }
                        resolve();
                    } catch (e) {
                        //login
                        state.isLogged = false;
                        state.dashboardInitiated = false;
                        reject(e);
                    }
                })
                .catch(function (error) {
                    // console.warn(error);
                    state.dashboardInitiated = false;
                    reject()
                });

        }),


    checkLogin: ({ commit, state }) =>


        //yonso edits
        commit('setAccessToken', null)


    // new Promise((resolve, reject) => {
    //     commit('setAccessToken', null)

    //     if (!state.access_token || typeof state.access_token == 'undefined')
    //         return reject()

    //     axiosV2.get('/auth/is-logged')
    //         .then(function (response) {

    //             if (response?.data?.logged == true) {
    //                 state.isLogged = true;
    //                 state.user = response.data.user

    //                 commit('setTenant', response.data.user.tenant)

    //                 resolve(response.data)
    //             } else {
    //                 state.isLogged = false;
    //                 commit('setTenant', null)
    //                 reject('not logged in')
    //             }
    //         })
    //         .catch(function (error) {
    //             console.warn(error);
    //             reject()
    //         });

    // })
    ,


    getBanners: () =>
        new Promise((resolve, reject) => {

            axios.get(Config.SETTINGS.API_URL + Config.API_ROUTES.banners, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        state.app.banners = (response.data.data ? response.data.data : []);
                        resolve()
                    } else {
                        state.app.banners = [];
                        reject()
                    }
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),

    getPresets: ({ state }) =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-preset')
                .then((response) => {
                    state.presets = (response.data ? response.data : []);
                    resolve(response.data)
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject(error)
                });

        }),

    getHalls: () =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('event-hall')
                .then((response) => {

                    state.halls = (response.data ? response.data : []);
                    resolve(state.halls)

                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),


    getTables: () =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-table')
                .then(function (response) {
                    state.tables = response.data;
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),

    getEquipments: () =>
        new Promise((resolve, reject) => {
            var $this = this;
            axiosV2.get('event-equipment')
                .then(function (response) {
                    state.equipments = response.data;
                    resolve(response.data)
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),




    getBanner: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axios.get(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + id, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),

    getPreset: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            axiosV2.get('event-preset/' + id)
                .then(function (response) {
                    resolve(response.data)
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),

    getEquipment: ({ commit }, id) => apiRequest('get', 'event-equipment/' + id),

    getHall: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            for (var hall of state.halls) {
                if (hall.id == id) resolve(hall);
            }
            return apiRequest('get', 'event-hall/' + id)
        }),

    getTable: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            for (var table of state.tables) {
                if (table.id == id) {
                    resolve(table);
                    return;
                }
            }
            return apiRequest('get', '/event-table/' + id).then(r => {
                resolve(r);
            }).catch(e => {
                reject('table not found')
            })
        }),



    addBanner: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axios.put(Config.SETTINGS.API_URL + Config.API_ROUTES.banners, data, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners');
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    // console.warn(error);
                    reject()
                });

        }),


    addPreset: ({ commit }, data) => apiRequest('post', 'event-preset', data).then(_res => {
        store.dispatch('getPresets');
        store.dispatch('getHalls');
    }),

    addTable: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.post('/event-table', data)
                .then(function (response) {
                    store.dispatch('getTables');
                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    addEquipment: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axiosV2.post('event-equipment', data)
                .then(function (response) {
                    store.dispatch('getEquipments');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });

        }),

    addHall: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            axiosV2.post('event-hall', data)
                .then((response) => {

                    store.dispatch('getHalls');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject(error)
                });
        }),

    updateBanner: ({ commit }, data) =>
        new Promise((resolve, reject) => {
            var $this = this;

            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + data.id, data, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners');
                        resolve(response.data.data)
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    updatePreset: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-preset/' + data.id, data)
                .then(function (response) {

                    store.dispatch('getPresets');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),




    updateHall: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-hall/' + data.id, data)
                .then((response) => {

                    store.dispatch('getHalls');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    updateTable: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('/event-table/' + data.id, data)
                .then(function (response) {

                    store.dispatch('getTables');
                    resolve(response.data)

                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    updateEquipment: ({ commit }, data) =>
        new Promise((resolve, reject) => {

            axiosV2.patch('event-equipment/' + data.id, data)
                .then(function (response) {
                    store.dispatch('getEquipments');
                    resolve(response.data)
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),


    updateAppSettings: ({ commit }, data) => apiRequest('post', 'settings', data),

    checkResetToken: ({ commit }, token) =>
        new Promise((resolve, reject) => {

            var o = { method: 'checkToken', token: token };
            axios.post(Config.SETTINGS.API_URL + Config.API_ROUTES.login, o, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    try {
                        if (response.data.status) {
                            resolve(true);
                        } else {
                            resolve()
                        }
                    } catch (e) {
                        reject()
                        console.warn('failed to process token check - ' + e.toString())
                    }
                })
                .catch(function (e) {
                    reject()
                    console.warn('token check failed - ' + e.toString());
                });

        }),


    addUser: ({ commit }, o) => apiRequest('post', 'users', o),
    deleteUser: ({ commit }, id) => apiRequest('delete', 'users/' + id),


    deleteBanner: ({ commit }, id) =>
        new Promise((resolve, reject) => {
            var $this = this;
            axios.delete(Config.SETTINGS.API_URL + Config.API_ROUTES.banners + '/' + id, Config.DEFAULT_API_SETTINGS)
                .then(function (response) {
                    if (response.data.status) {
                        store.dispatch('getBanners')
                        resolve(response.data);
                    } else {
                        reject(response.data)
                    }
                })
                .catch(function (error) {
                    console.warn(error);
                    reject()
                });

        }),

    deletePreset: ({ commit }, id) => apiRequest('delete', '/event-preset/' + id).then(_r => {
        store.dispatch('getPresets')
    }),

    deleteHall: ({ commit }, id) => apiRequest('delete', 'event-hall/' + id).then(_r => {
        store.dispatch('getHalls')
    }),

    deleteTable: ({ commit }, id) => apiRequest('delete', 'event-table/' + id).then(_r => {
        store.dispatch('getTables')
    }),

    deleteEquipment: ({ commit }, id) => apiRequest('delete', 'event-equipment/' + id).then(_r => {
        store.dispatch('getEquipments')
    }),

    saveLang: ({ commit }, lang) => {
        try {
            localStorage.setItem('lang', lang);
        } catch (e) {
            console.error('failed to access localStorage')
        }
        state.preferences.lang = lang;
    },

    saveMenuState: ({ commit }, _) => {
        try {
            localStorage.setItem('menuState', _);
        } catch (e) {
            console.log('failed to access localStorage')
        }
        state.preferences.menuState = _;
    },
    showLoading({ state }) {
        state.isLoading = true
    },
    hideLoading({ state }) {
        state.isLoading = false
    }
}

// getters are functions
const getters = {


    isLoading() {
        return state.isLoading;
    },
    isLogged() {
        return state.isLogged;
    },
    getUser() {
        return state.user;
    },
    dashboardInitiated() {
        return state.dashboardInitiated;
    },
    getPresets() {
        return state.presets;
    },
    getConfig() {
        return Config;
    },
    appLogo(state) {
        if (isPlanup()) {
            return `${Config.SETTINGS.APP_URL}static/images/planup-logo.png`
        } else {
            return state.app.logo
                ? Config.SETTINGS.MEDIA_URL + 'logos/' + state.app.logo
                : `${Config.SETTINGS.APP_URL}static/images/logo.png`
        }
    }
}

// A Vuex instance is created by combining the state, mutations, actions,
// and getters.
const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    modules: {
        // PandaCrmModule,
        // GuestStore,
        // EventStore,
        // UserStore,
        // TasksStore,

        // EventItemStore,
        // MeetingStore,
        // AuthStore,
        // FileStore
    }
});

export default store;
