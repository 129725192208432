const TPL_HE = {
    ils: '₪',
    langName: "עברית",
    langs: [
        { code: 'he', name: 'עברית' },
        { code: 'en', name: 'English' },
        { code: 'ru', name: 'Русский' },
    ],
    loginTitle: "כיף לראות אותך שוב!",
    email: "אימייל",
    password: "סיסמא",
    login: "התחברות",
    app_snippets: "קוד להטמעה בHeader",
    forgotPw: "שכחתי סיסמה",
    hello: "שלום",
    menu: "תפריט",
    reservedTableWarning: "שולחן זה הינו שולחן רזרבה ולא ניתן להושיב עליו",
    notifications: "התראות",
    reserved: "R",
    reserve: "רזרבה",

    markAsReserved: "סמן כרזרבה",
    myPlace: "המקום שלי",
    notificationsTitle: "יש לך <span>{num}</span> התראות חדשות!",
    logoff: "התנתק",
    invalidLogin: "הפרטים שהוזנו אינם נכונים",
    goToLogin: "התחברות למערכת >",
    resetMyPass: "אפס לי את הסיסמא!",
    forgottenPass: "שכחת סיסמא?",

    profession: 'מקצוע',
    searchUser: "חיפוש לקוח",
    addUser: "הוסף משתמש",
    userEdit: "עריכת לקוח",
    hallEdit: "עריכת אולם",
    bannerEdit: "עריכת באנר",
    presetEdit: "עריכת פריסה",
    banner_file: "העלאת באנר",
    notExist: "לא הוזן",
    okButton: "סגור",
    userAdded: "המשתמש נוסף בהצלחה",
    bannerAdded: "הבאנר נוסף בהצלחה",
    presetAdded: "הפריסה נוספה בהצלחה",
    hallAdded: "האולם נוסף בהצלחה",
    eventAdded: "האירוע נוסף בהצלחה",
    eventUpdated: "האירוע נערך בהצלחה",
    presetEdited: "הפריסה נערכה בהצלחה",
    bannerEdited: "הבאנר נערך בהצלחה",
    uploadFailed: "העלאת הקובץ נכשלה, אנא נסו שוב",
    id: "מספר",
    name: "שם",
    userType: "סוג לקוח",
    options: "אפשרויות",
    edit: "ערוך",
    delete: "מחק",
    type: "סוג",
    back: "חזור",
    generalDetails: "פרטים כללים",
    fullName: "שם מלא",
    company: "חברה",
    role: "תפקיד",
    phone: "פלאפון",
    mobile: "נייד",
    address: "כתובת",
    passwordUpdate: "עידכון סיסמא",
    strictTables: "חסום חריגת כמות מושבים בשולחן",
    resevedSeatingAllowed: "אפשר הושבה בשולחנות רזרבה",
    strictTablesWarning: "כמות המושבים בשולחן מוגבלת, יש לפנות למנהל האירוע לפתיחת ההגבלה",
    conferenceOnly: "(כנס)",
    currentPassword: "סיסמא נוכחית",
    newPassword: "סיסמא חדשה",
    verifyNewPass: "אימות סיסמא חדשה",
    save: "שמור",
    download: "הורדה",
    saveChanges: "שמור שינויים",
    cancel: "התחרטתי",
    yes: "כן",
    no: "לא",
    date: "תאריך",
    place: "מקום",
    saveMeeting: "שמור הצעת מחיר",
    files: "קבצים",
    addFile: "הוספת קובץ",
    changeFile: "עדכון קובץ",
    appointmentAdded: "הפגישה נשמרה",
    quotationAdded: "הצעת המחיר נשמרה",
    notFound: "אופס.. נראה שהגעתם למקום הלא נכון",
    shallWeGoBack: "שנחזור אחורה?",
    passwordLength: "אורך הסיסמא צריך להיות לפחות 6 תווים",
    unrevetableOperation: "פעולה זו אינה ניתנת לביטול!",
    deletePrompt: "האם אתה בטוח שברצונך למחוק את הלקוח?",
    resetGuestListPrompt: "האם אתה בטוח שברצונך לאפס את רשימת האורחים?",
    removeItemPrompt: "האם אתה בטוח שברצונך למחוק את הפריט?",
    opFailed: "הפעולה נכשלה, אנא נסו שוב",
    saveFailed: "השמירה נכשלה, אנא נסו שוב",
    deleteGuestPrompt: "האם אתה בטוח שברצונך למחוק את האורח?",
    changePresetPrompt: "שים לב! לאירוע זה כבר הוגדרה פריסה, החלפת פריסה תמחק את הפריסה הקודמת",
    resetPresetPrompt: "שים לב! איפוס הפריסה ימחק את כל השולחנות והציוד מהפריסה של האירוע",
    deleteBannerPrompt: "האם אתה בטוח שברצונך למחוק את הבאנר?",
    deletePresetPrompt: "האם אתה בטוח שברצונך למחוק את הפריסה?",
    deleteHallPrompt: "האם אתה בטוח שברצונך למחוק את האולם?",
    deleteEquipmentPrompt: "האם אתה בטוח שברצונך למחוק את הציוד?",
    deleteTablePrompt: "האם אתה בטוח שברצונך למחוק את השולחן?",
    eventEmailExist: "כתובת הדוא\"ל שהוזנה כבר קיימת במערכת ולא ניתן לשייך יותר מאירוע אחד למשתמש",
    emailExist: "כתובת הדוא\"ל שהוזנה כבר קיימת במערכת",
    fieldRequired_email: 'שדה אימייל הינו שדה חובה!',
    fieldRequired_name: 'שדה שם הינו שדה חובה!',
    fieldRequired_password: 'שדה סיסמא הינו שדה חובה!',
    detailsSaved: 'הפרטים נשמרו בהצלחה',
    passwordsNotMatching: 'הסיסמאות שהוזנו אינן תואמות',
    incorrectPassword: 'הסיסמא שהוזנה אינה נכונה',
    defaultGroups: 'קבוצות דיפולטיביות',
    banner_targets: [
        { id: 'hall', title: 'אולם' },
        { id: 'photography', title: 'צלם' },
        { id: 'dj', title: 'Dj' },
        { id: 'attractions', title: 'אטרקציות' },
        { id: 'magnets', title: 'מגנטים' },
        { id: 'alcohol', title: 'אלכוהול' },
        { id: 'specials', title: 'אטרקציות מיוחדות' },
        { id: 'design', title: 'עיצוב' },
        { id: 'hinna', title: 'חינות' },
        { id: 'rabbai', title: 'רב' },
        { id: 'makeup', title: 'איפור' },
        { id: 'hair', title: 'שיער' },
        { id: 'dress', title: 'שמלות כלה' },
        { id: 'suites', title: 'חליפות חתן' },
        { id: 'rings', title: 'טבעות' },
        { id: 'accessories', title: 'אקססוריז' }
    ],

    shapes: [
        { id: 'squareTable', title: 'ריבוע' },
        { id: 'circleTable', title: 'עיגול' },
        { id: 'rectangleTable', title: 'מלבן' },
        { id: 'ellipseTable', title: 'אליפסה' },
        /*{id:'drop',title:'טיפה'},*/
        { id: 'wood', title: 'עץ' },
    ],

    /*MENUS*/
    menu_dashboard: 'ראשי',
    menu_settings: 'הגדרות',
    menu_users: 'משתמשים',
    menu_guests: 'אורחים',
    menu_seating: 'סידורי ישיבה',
    menu_screen: 'מסך',
    menu_reports: 'דוחות',
    menu_sms: 'SMS',
    menu_usersFor_1: 'ספקים',
    menu_usersFor_2: 'לקוחות',
    menu_usersFor_3: 'אורחים',
    menu_calendar: 'יומן',
    menu_messages: 'הודעות',
    menu_events: 'אירועים',
    menu_drinks: 'ניהול משקאות',
    menu_waiters: 'ניהול מלצרים',
    menu_billing: 'חשבונות',
    menu_analytics: 'נתונים ודוחות',
    menu_log: 'פעולות אחרונות',
    menu_milestones: 'אבני דרך',

    milestones: 'אבני דרך',
    Contract:'חוזה',

    users: 'לקוחות',
    settings: 'הגדרות',
    templates: 'תבניות',

    /*USER TYPES*/
    userType_1: 'מנהל מערכת',
    userType_2: 'ריסיילר',
    userType_3: 'לקוח',
   
    userType_5: 'מנהל אולם',
    userType_6: 'עובד אולם',

    /*PAGE TITLES*/
    page_title_users: 'לקוחות',
    page_title_dashboard: 'מסך ראשי',
    page_title_guests: 'אורחים',
    page_title_guestsMyPlace: 'המקום שלי',
    page_title_sms: 'SMS',
    page_title_seating: 'הושבת אורחים',
    page_title_screen: 'מסך',
    page_title_calendar: 'לוח אירועים',
    page_title_Calendar: 'לוח אירועים',
    page_title_login: 'מסך התחברות',
    page_title_notFound: 'העמוד לא נמצא',
    page_title_passwordRecovery: 'שחזור סיסמא',
    page_title_passwordRecoveryWithToken: 'עדכון סיסמא',
    page_title_addUser: 'הוספת לקוח',
    page_title_addEvent: 'הוספת אירוע',
    page_title_addPreset: 'הוספת פריסה',
    page_title_editPreset: 'עריכת פריסה',
    page_title_editUser: 'עריכת לקוח',
    page_title_editEvent: 'עריכת אירוע',
    page_title_profile: 'פרופיל',
    page_title_settings: 'הגדרות',
    page_title_editBanner: 'עריכת באנר',
    page_title_addBanner: 'הוספת באנר',
    page_title_settingsTabSelected: 'הגדרות',
    page_title_eventPreset: 'עריכת פריסה',
    page_title_EventMiddleScreen: 'ניהול אירוע',
    page_title_QuotationMiddleScreen: 'ניהול הצעת מחיר',
    page_title_MeetingMiddleScreen: 'ניהול פגישה',
    page_title_screenEvent: 'ניהול הגדרות מסך אירוע',
    page_title_tables: 'מצב שולחנות',
    page_title_arrival: 'מצב הגעה',
    page_title_: '',
    page_title_table_statsues: 'מצב שולחנות',
    page_title_event_builder: 'בניית אירוע',
    page_title_user_messages: 'הודעות',

    /** General */
    search: 'חיפוש ',
    price: 'מחיר',
    image: 'תמונה',
    actions: 'פעולות',
    next: 'הבא',
    prev: 'הקודם',
    of: 'מתוך',
    'Rows per page': 'שורות לעמוד',


    Dishes: 'מנות',
    napkins: 'מפיות',
    tabletop: 'מפות',
    design: 'עיצוב',

    'dish name': 'מנות',
    'category': 'קטגוריה',
    'add dish': 'הוסף מנה',
    'add dish category': 'הוסף קטגורייה',


    new_msg: 'הודעה חדשה',
    due_date: 'תאריך לביצוע',
    sender: 'שולח',
    title: 'כותרת',
    recipients: 'נמענים',
    content: 'תוכן',
    send: 'שלח',
    completed: 'בוצע',

    created_successfully: 'נשמר בהצלחה',
    done_successfully: 'בוצע בהצלחה!',
    deleted_successfully: 'נמחק בהצלחה!',
    parameters: 'פרמטרים',
    messages: 'הודעות',
    permissions: 'הרשאות',

    limit: 'הגבלה',
    confirmDelete: 'האם אתה בטוח שאתה רוצה למחוק?',

    support: 'תמיכה',
    refreshData: 'עדכן נתונים',
    filterTables: 'סנן שולחנות',
    optional: '- אופציונלי',
    side1: 'כסאות צד ראשון',
    side2: 'כסאות צד שני',
    side3: 'כסאות צד שלישי',
    side4: 'כסאות צד רביעי',
    contact: 'צור קשר',
    makeSearch: 'עליך לבצע חיפוש בכדי לראות את האורחים',
    getStarted: 'איך מתחילים?',
    close: 'סגור',
    open: 'פתוח',
    contactModalTitle: 'אנחנו תמיד כאן לשירותכם!',
    callUs: 'התקשרו אלינו',
    tablesStatus: 'מצב שולחנות',
    arrivalStatus: 'מצב הגעה',
    defaultPhone: '0549040212',
    contactSubject: 'סיבת הפנייה',
    howCanWeHelp: 'במה נוכל לעזור?',
    hereYouSubmit: 'כאן שולחים',
    contactFailed: 'שליחת הטופס נכשלה, אנא נסו שוב',
    contactSuccess: 'ההודעה נשלחה בהצלחה, אחד מנציגנו יצור עמכם קשר בהקדם',

    closeEvents: 'אירועים קרובים',
    searchEvent: 'חפש אירוע',
    addEvent: 'אירוע חדש',
    toAllEvents: 'לכל האירועים',
    hall: 'אולם',
    guestsNum: 'מספר אורחים',
    hostName: 'פרטי המארח',
    deleteEvent: 'מחק אירוע',
    deleteEvent_2: 'מחיקת אירוע',
    editEvent: 'ערוך אירוע',


    createTask: 'ליצור משימה',
    event: 'אירוע',
    event_planner: 'תיק אירוע',
    offer: 'הצעת מחיר',
    meeting: 'פגישה',
    holiday: 'חג',
    task: 'משימה',
    eventOwnerDetails: 'פרטי בעל האירוע',
    eventOwnerName: 'שם בעל האירוע',
    eventDetails: 'פרטי האירוע',
    eventName: 'שם האירוע',
    eventType: 'סוג אירוע',
    eventDate: 'תאריך האירוע',
    notes: 'הערות',
    printNotes: 'הערות מערכת',
    systemNotes: 'הערות מערכת',
    coupleNotes: 'הערות זוג',
    editableLabel: 'ניתן לעריכה בלחיצה',
    saveEvent: 'שמור אירוע',
    chooseExisting: 'בחירת לקוח קיים',
    chooseEvent: 'בחירת אירוע לקיוסק',
    closeApp: 'סגור קיוסק',
    closeBrowserPrompt: 'האם אתה בטוח שברצונך לסגור את הקיוסק?',
    choose: 'בחר',
    logoffUser: 'נתק משתמש',
    preset: 'פריסה',
    eventEdit: 'עריכת אירוע',
    sketch: 'סקיצה',
    app_settings: 'אפליקציה',
    halls: 'אולמות',
    tableTypes: 'סוגי שולחנות',
    equipment: 'ציוד',
    banners: 'באנרים',
    presets: 'פריסות',
    searchBanner: 'חיפוש באנר',
    banner_name: 'שם הבאנר',
    banner_image: 'תמונה',
    banner_url: 'כתובת יעד',
    banner_target: 'קהל יעד',
    addBanner: 'הוסף באנר',
    bannerAdd: 'הוספת באנר',
    presetAdd: 'הוספת פריסה',

    events: 'אירועים',
    hall_name: 'שם האולם',
    hall_events: 'מס\' אירועים',
    hall_presets: 'מס\' פריסות(תבניות)',
    addHall: 'הוסף אולם',
    hallAdd: 'הוספת אולם',
    searchTable: 'חיפוש שולחן',
    searchHall: 'חיפוש אולם',
    searchEquipment: 'חיפוש ציוד',
    change_hall: 'החלף אולם',

    table_name: 'שם שולחן',
    shape: 'צורה',
    capacity: 'קיבולת',
    addTable: 'הוסף שולחן',
    people: 'איש',
    tableAdd: 'הוספת שולחן',
    tableAdded: 'השולחן נוסף בהצלחה',
    tableEdit: 'עריכת שולחן',

    equipment_name: 'שם הציוד',
    addEquipment: 'הוסף ציוד',
    color: 'צבע',
    equipmentAdd: 'הוספת ציוד',
    equipmentEdit: 'עריכת ציוד',
    equipments: 'ציוד',
    equipmentAdded: 'הציוד נוסף בהצלחה',
    app_name: 'שם האפליקציה',
    app_logo: 'לוגו (תמונה עם רקע שקוף 1024x768)',
    app_kiosk_logo: 'לוגו קיוסק (תמונה עם רקע שקוף 300x77)',
    app_domain: 'דומיין',
    updateLogo: 'עדכן לוגו',
    updateKioskLogo: 'עדכן לוגו קיוסק',

    usersFor_1: 'ספקים',
    usersFor_2: 'לקוחות',
    usersFor_3: 'אורחים',
    usersFor_5: 'משתמשים',
    appSettingsUpdated: 'ההגדרות נשמרו בהצלחה',
    select: 'בחר',
    finish: 'סיים בחירה',
    startSelecting: 'התחל בחירה',
    saveAppointment: 'שמור פגישה',
    clientName: 'שם הלקוח',
    workersNum: 'כמות עובדים',
    brutoGuests: 'כמות מוזמנים ברוטו',
    requiredGuests: 'כמות התחייבות לאולם',
    type_sitting: 'הושבה',
    type_alcohol: 'אלכוהול',
    type_eventManagement: 'ניהול אירוע',
    type_totalManage: 'ניהול אירוע כולל',
    type_seatingAndAlcohol: 'הושבה + אלכוהול',
    type_seatingAndManagement: 'הושבה + ניהול אירוע',
    day: 'יום',
    days: 'ימים',
    hours: 'שעות',
    hour: 'שעה',
    minutes: 'דקות',
    seconds: 'שניות',
    month: 'חודש',
    week: 'שבוע',
    quantity: 'כמות',
    year: 'שנה',
    schedule: 'סדר יום',
    filterEvents: 'סנן אירועים',
    eventStatus: 'מצב האירוע',
    event_approved: 'מאושר',
    event_not_approved: 'לא מאושר',
    or: 'או',
    banner_resellers: 'ייעודי לריסיילרים',
    guestsSeatingLimit: 'הגבלת כמות אורחים להושבה',
    guestsSeatingLimitNotification_title: 'הגבלת הושבה',
    guestsSeatingLimitNotification_text: 'הגעת למכסת ההושבה באירוע שלך ({quantity}), לפרטים נוספים פנה למנהל האירוע.',
    mikra: 'מקרא',
    eventType_wedding: 'חתונה',
    eventType_barMitzvah: 'בר מצווה',
    eventType_circumcision: 'ברית',
    eventType_bussines: 'עסקי',
    eventType_conference: 'כנס',
    eventType_other: 'אחר',
    eventType_holiday: 'חג/מיוחד',
    eventType_meeting: 'פגישה',
    eventType_quotation: 'הצעת מחיר',
    all: 'הכל',
    hallPreset: 'פריסת אולם',
    adminMenu: 'תפריט מנהל',
    guestsSitting: 'הושבת אורחים',
    preset_name: 'שם הפריסה',
    preset_background: 'רקע הפריסה',
    addPreset: 'הוסף פריסה',
    editPreset: 'ערוך פריסה',
    searchPreset: 'חפש פריסה',
    guests: 'אורחים',
    ofGuests: 'מהאורחים',
    notResponded: 'לא הגיבו',
    willCome: 'יגיעו',
    mayCome: 'אולי',
    notComing: 'לא יגיעו',
    needs_attension: 'רשומות לטיפול',
    noGroup: 'לחץ לבחירה',
    toEvent: 'לאירוע',
    sittingArrangements: 'סידורי הושבה',
    choosePreset: 'בחר תבנית',
    disablePresetChanges: 'חסום את המארח מביצוע שינויים',
    bama: 'במה',
    bar: 'בר',
    guestDelete: 'מחיקת אורח',
    hupa: 'חופה',
    bufe: 'בופה',
    tableType: 'סוג שולחן',
    square: 'ריבוע',
    circle: 'עיגול',
    continue: 'המשך',
    passwordRequired: 'נא להזין את סיסמאת המשתמש ממנו הקיוסק מחובר',
    rectangle: 'מלבן',
    showTimerTxt: 'הצג טיימר אירוע (48 שעות לפני האירוע)?',
    phoneDuplicatesTitle: 'כפילויות במספרי טלפון',
    phoneDuplicatesText: 'אותרו כפילויות במספרי טלפון<br/>האורחים הבאים הוזנו למערכת אך ללא מספר פלאפון!<br/>',
    ellipse: 'אליפסה',
    drop: 'טיפה',
    wood: 'עץ',
    tools: 'כלים',
    copy: 'העתקה',
    autoNum: 'מספור',
    total: 'סה״כ',
    extras: 'תוספות',
    hallPlaces: 'מקומות ישיבה באולם',
    mehiza: 'מחיצה',
    taken: 'תפוסים',
    available: 'פנויים',
    resetPreset: 'איפוס פריסה',
    saveAsTemplate: 'שמור כתבנית',
    saveAsTemplateTitle: 'שמירת פריסה כתבנית',
    saveAsTemplateText: 'נא להזין שם לפריסה',
    presetNameRequired: 'שם הפריסה הינו חובה',
    hoursUntilEvent: 'עוד {hours} שעות לאירוע',
    timerTill: 'לאירוע שלך',
    timerLockDown: 'לנעילת ההושבה.',
    timerIsLocked: 'ההושבה נעולה.',
    fromEventDesign: 'מבחירת העיצוב הושלם',
    seatedGuests: 'סידורי הושבה',
    guestsInSystem: 'רשימת אורחים',
    smsSent: 'סמסים נשלחו כבר',
    importGuests: 'ייבוא אורחים',
    addGuest: 'הוספת אורח',
    addGuest_2: 'הוספת אורח/ת',
    filterGuests: 'סנן אורחים',
    group: 'קבוצה',
    guestStatus: 'סטטוס הגעה',
    searchGuest: 'חפשו לפי שם האורח/ מס׳ שולחן',
    show: 'הצג',
    export: 'ייצוא',
    outOf: 'מתוך',
    previous: 'הקודם',
    pageNum: 'עמוד מס׳ {page}',
    next: 'הבא',
    contactInfo: 'פרטי קשר',
    tableNum: 'מס׳ שולחן',
    tableName: 'שם השולחן',
    tableNumber: 'שולחן מס׳',
    totalPlaces: 'סה״כ מקומות',
    moreDetails: 'פרטים נוספים',
    tracking: 'מעקב',
    sendLoginDetails: 'שלח פרטי התחברות ללקוח',
    loginDetailsSent: 'פרטי ההתחברות נשלחו ללקוח (אימייל)',
    groups: 'קבוצות',
    guestName: 'שם אורח',
    loading: 'אנא המתן, המערכת טוענת נתונים',
    pleaseWait: 'אנא המתן',
    groupName: 'שם הקבוצה',
    addGuest_3: 'הוסף אורח/ת',
    guestSaved: 'האורח נשמר בהצלחה',
    guestMarkTooltip: 'לחיצה על אורח משנה את סטטוס הגעתו',
    import: 'ייבא',
    selectFile: 'בחר קובץ',
    excel_file: 'קובץ Excel (xls,xlsx,csv)',
    resetGuestList: 'איפוס רשימת אורחים',
    beforeWeReset: 'רגע לפני שאנחנו מאפסים',
    enterYourPassword: 'הזן את הסיסמא שלך',
    resetGuests: 'לך על זה',
    print: 'הדפס',
    whoIsWithMe: 'מי איתי בשולחן?',
    presetNameRequired: 'מה עם הסיסמא?',
    passwordIncorrect: 'הסיסמא שהוזנה לא נכונה..',
    importFileExample: 'קובץ אקסל לדוגמא',
    importFileExampleConference: 'קובץ אקסל לדוגמא (כנס)',
    importSuccessTitle: 'הייבוא הצליח!',
    importSuccessText: '{added} אורחים נוספו ו {failed} אורחים נכשלו בהוספה',
    notGuestsFound: 'לא נמצאו אורחים',
    noBannersFound: 'לא נמצאו באנרים',
    noResults: 'אין תוצאות',
    seating: 'סידורי ישיבה',
    seats: 'כסאות',
    searchGuestBy: 'חפש לפי שם אורח/שולחן',
    guestsStatus: 'מצב האורחים',
    seated: 'הושבו',
    useGridMode: 'יישור לפי הגריד',
    useGroupSeating: 'קיבוץ הושבה עפ"י שמות שולחן',
    seatingImageHint: '*סידורי ישיבה - על מנת לראות את סידורי הישיבה יש לשמור קודם את הצילום פריסה באמצעות הכפתור שבמסך "פריסת אירוע".',
    arrived: 'הגיעו',
    isArrived: 'הגיע',
    willArrived: 'בטוח מגיעים',
    currentArrivalStatus: 'סטטוס הגעה נוכחי',
    notSeated: 'לא הושבו',
    seated2: 'מושבים',
    notSeatedGuest: 'לא הושב',
    notSeatedGuest_2: 'לא מושב',
    tableSwap: 'החלף בין אורחי שולחנות',
    chooseGroup: 'בחר קבוצה',
    editGuest: 'ערוך אורח',
    noGuestGroup: 'ללא קבוצה',
    servicesLbl: 'שירותים שנלקחו',
    startNumberRequired: 'המספר ההתחלתי חובה!',
    capacityRequired: 'כמות האורחים בשולחן חובה!',
    endNumbering: 'סיים מספור',
    endReserving: 'סיים הגדרת רזרבות',
    firstUseSelectMode: 'עליך ללחוץ על כפתור "בחירה" לפני תחילת הפעולה',
    startNumberTitle: 'עוד רגע..',
    startNumberText: 'הזן את המספר ממנו להתחיל',
    capacityChangeText: 'הזן את כמות האורחים הרצויה בשולחן',
    deleteEventPrompt: 'האם אתה בטוח שברצונך למחוק את האירוע?',
    invitationImage: 'תמונה להזמנה',
    whatImageInvitation: 'איזה תמונה תרצו שתופיע על בהזמנה לאירוע שלכם?',
    imagesGallery: 'גלריית תמונות',
    backgroundImages: 'תמונות רקע שיוחלפו על המסך שיוצג באירוע שלכם',
    deleteImage: 'מחק תמונה',
    screenSettings: 'הגדרות מסך',
    attachments: 'קבצים מצורפים',
    rollbackNote: 'ניתן לשחזר שינויים קדימה ואחורה באמצעות ctrl+z ו ctrl+shift+z',
    interestingPart: 'הגעתם לחלק המעניין',
    reportsTitle: 'כדי לצפות בדוחות, עליכם ללחוץ על הדוח המבוקש',
    acceptations: 'אישורי הגעה',
    guestsAttendances: 'נוכחות אורחים',
    attendance: 'נוכחות',
    conferencePrintBg: 'קובץ רקע להדפסה (כנס)',
    conferencePrintBgTitle: 'גובה 27.6 ס"מ ורוחב 9.6 ס"מ',
    editGuest_2: 'עריכת כרטיס אורח/ת',
    deleteGuest: 'מחק אורח/ת',
    hereYouSave: 'כאן שומרים',
    comingQuantity: 'כמות מגיעים',
    eventStatus_WAT: 'ממתין להזנת אורחים',
    eventStatus_RFG: 'מוכן להסבר הושבה',
    eventStatus_FIS: 'סיים הושבה',
    resetUserPassword: 'איפוס סיסמת משתמש',
    tables: 'שולחנות',
    table: 'שולחן',
    printPreset: 'הדפס',
    screenShotSaved: 'צילום הפריסה נשמר בהצלחה',
    noScreenShot: 'לא נמצא צילום סידורי ישיבה, יש לעבור למסך הפריסה ולשמור מחדש',
    amazingEventTitle: 'שיהיה אירוע מדהים!',
    mainScreen: 'הדף הראשי',
    guestsOrder: 'סידור השולחנות',
    whatIsYourName: 'איך קוראים לך?',
    noGuestFound: '-אין תוצאות-',
    thanksForComing: 'תודה שהגעתם!',
    tt_gender: 'זכר/נקבה',
    tt_vip: 'VIP',
    tt_access: 'נכה',
    tt_veggie: 'צמחוני',
    tt_kosher: 'כשרות מיוחדת',
    tt_male: 'זכר',
    tt_female: 'נקבה',
    not_exist: 'אין',
    selectFirstTable: 'בחר שולחן ראשון',
    selectSecondTable: 'בחר שולחן שני',
    tablesSwapPrompt: 'האם אתה בטוח שברצונך להחליף בין אורחי השולחנות?',
    selectEventRequired: 'יש לבחור את האירוע המבוקש מהרשימה',


    fatalError: "אירעה שגיאה אנא נסו שנית",
    fatalErrorReports: "אירעה שגיאה אנא בדקו שהוספתם אורחים לאירוע",
    minimumCapacityError: "השולחן חייב להכיל לפחות {0} מקומות",
    sendToPanda: "שלח לCALLUP",

    'Type To enter new group': 'הקלד ליצירת קבוצה חדשה',
    'Add Group': 'צור קבוצה',

    'Add Meeting': 'יצירת פגישה',
    'Upcoming Meetings': 'פגישות קרובות',
    description: 'תיאור',
    create: 'יצירה',
    view: 'צפייה',
    delete: 'מחיקה',
    update: 'עדכון',
    status: 'סטטוס',
    add: 'הוסף',
    user: 'משתמש',
    time: 'זמן',
    Action: 'פעולה',
    selected: 'נבחרו',


    quotation: 'הצעת מחיר',
    calendar: 'יומן',
    tasks: 'משימות',

    advancePayment: 'מקדמה',
    advancePaymentType: 'סוג תשלום מקדמה',
    oneDishCost: 'מחיר מנה',
    dishNotes: 'הערות מנה',
    workers: 'עובדים',

    canopi: 'חופה',

    ChooseMenu: 'בחירת תפריט',
    ChooseDishes: 'בחירת מנות',
    ChooseDesign:'בחירת עיצוב',
    SuppliersContacts: 'אנשי קשר וספקים',
    CanomyWelcome:'חופה וקבלת פנים',
    TimeSchedule:'לוח זמנים',
    TODO:'מטלות',
    
    billing: 'חשבונות',
    drinks: "משקאות",
    drink: "משקה",
    usage: 'שימוש',
    waiters: "מלצרים",
    age: "גיל",
    food: "מנה",
    napkin: "מפית",

    // myProfile
    myProfile: "הפרופיל שלי",
    'my meetings': "הפגישות שלי",
    'my quotes': "הצעות מחיר",
    outof: 'מתוך',
    for: 'עבור',

    contract : 'חוזה',



    //yonso add
    bizCategory: "סוג העסק",
    editUserServices: "שרותים",
    bizServices: "שירותי העסק",
    menu_customers: "פניות",
    eventPlace: "מקום האירוע",
    eventGuestCount: 'מספר אורחים צפוי',
    servicesForCustomer: 'שרותים ללקוח',
    enterUserAndPassword: 'הזינו שם משתמש וסיסמה על מננת להתחבר למערכת',
    loginButton: 'כניסה',
    customerPage: 'כרטיס לקוח',
    logPage:'פעולות אחרונות',
    customersPage: 'פניות',
    customerPageContactsTab: 'אנשי קשר',
    customerPageRatsonTab: 'בקשה ראשונית',
    customerPageQuotationTab: 'פרטי האירוע',
    customerPageSetNewMeetingTab: 'קביעת פגישה',
    customerPageMeetingsTab: 'פגישות',
    customerPageTasksTab: 'אבני דרך / משימות',
    customerPageLogTab: 'תיעוד תהליך',
    customerPageDocumentsTab: 'מסמכים',
    customerPageGeneralInfoTab: 'תמונת על',
    customerPageCommunicationTab: 'הודעות ומסרים',
    customerSource: 'מקור הפניה',
    typeOfCustomer: 'סוג לקוח',
    newCustomerPageTitle: 'לקוח חדש',
    customerPageAddEventTitle: 'יצירת אירוע - ראשוני',
    chosenInMultiselect: 'נבחר',
    enterLeadSource: 'הזינו את מקור הפנייה',
    chooseLeadSource: 'בחרו את מקור הפנייה',
    contactNoTitle: 'ללא תפקיד',
    customerPageFinishNewCustomerTab: 'סיום',
    noMatchFoundInList: 'לא נמצאה התאמה ברשימה',
    noPhone: 'ללא מספר טלפון',
    generalContactName: 'איש קשר',
    removeContactButton: 'הסר',




    menu_orginizationSettings: 'הגדרות ארגון',
    menu_parametersSettings: 'הגדרות פרמטרים',
    menu_formsSettings: 'תבניות מסמכים',
    menu_processSettings: 'הגדרות תהליכים',
    menu_userSettings: 'הגדרות משתמשים',
    menu_catalogSettings: 'קטלוג מוצרים',
    menu_templateSettings: 'הגדרות תבניות',
    menu_documents: 'תבניות מסמכים',
    menu_tasks: 'משימות',
    menu_accounting: 'הנה"ח',
    menu_messageTemplates: 'תבניות הודעות',


    orginizationSettings_generalTab: 'ראשי',
    orginizationSettings_billingTab: 'פלנאפ ישן',
    orginizationSettings_googleTab: 'התממשקויות',
    orginizationSettings_reshutHamisimTab: 'רשות המיסים',



    generalTask:'משימה כללית',

    updateCustomerContacts: 'עדכן אנשי קשר',

    validation_pleaseEnterFirstName: 'נא להזין שם פרטי',
    validation_pleaseEnterPhone: 'נא להזין טלפון ',

    errorInContactDetails: 'נמצאו שגיאות בפרטי אנשי הקשר.',


    eventClass: 'סוג אירוע',
    eventList: 'רשימת אירועים',

    enterContactTitle: 'הזן תפקיד',
    chooseContactTitle: 'בחר תפקיד',

    products: 'מוצרים ושירתים',
    crewMembers: 'צוות',
    planupMeetingTypes: 'סוג פגישה',
    taskTypes: 'סוג משימה',


    //quickedit

    addToQuickEditList: 'הוסף לרשימה',
    QuickEditContactTitles: 'ניהול מהיר - תפקידי אנשי קשר',
    QuickEditCrewMembers: 'ניהול מהיר - אנשי צוות',
    QuickEditEventClasses: 'ניהול מהיר - סוגי אירוע',
    QuickEditEventPlaces: 'ניהול מהיר - מקומות לאירוע',
    QuickEditProducts: 'ניהול מהיר - מוצרים ושירותים',
    QuickEditMeetingPlaces: 'ניהול מהיר - מקומות לפגישה',
    QuickEditPlanupMeetingTypes: 'ניהול מהיר -  סוגי פגישות',
    QuickEditTaskTypes: 'ניהול מהיר -  סוגי משימות ',
    QuickEditOtherVendors: 'ניהול מהיר - ספקים אחרים',
    QuickEditOtherVendorCategories: 'ניהול מהיר - קטגוריות ספקים נוספים',
    QuickEditCustomerSources: 'ניהול מהיר - מקורות לידים',
    QuickEditPlanupCustomerStatuses: 'ניהול מהיר - סטטוסי לידים',
    QuickEditPlanupEventStatuses: 'ניהול מהיר - סטטוס פניה',




    //event date picker
    askIfContinueWithThisDate: 'האם להמשיך עם התאריך הזה?',
    choosingDate: 'בחירת תאריך',
    requestedDate: 'תאריך רצוי',
    chosenDates: 'תאריכים שנבחרו',




    targetForNewEntityModalTitleFor_event: 'למי תרצו להוסיף פניה חדשה?',
    targetForNewEntityModalTitleFor_meeting: 'למי תרצו להוסיף פגישה חדשה?',
    targetForNewEntityModalTitleFor_task: 'למי תרצו להוסיף משימה חדשה?',
    targetForNewEntityModalTitleFor_taxDocument: 'למי תרצו להפיק את המסמך?',




    contacts: 'אנשי קשר',
    estimated: 'משוער',
    


    setMeeting: 'לקבוע פגישה',
    createEvent: 'לבדוק / לקבוע אירוע',

    clickOnDateModalTitle: 'מה תרצו לעשות בתאריך',

    newCustomer: 'לקוח חדש',
    existingCustomer: 'לקוח קיים',
    withoutCustomer: 'ללא לקוח',



    customerSearch: 'חיפוש לקוח'


}

export default TPL_HE



