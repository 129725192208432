<template>
    <Modal ref="modal"
           :hideFooter="true"
           :noClose="true"
           title="עדכון סטטוס פניה">
        <template v-slot:body
                  v-if="event">

            לאיזה סטטוס תרצו לעדכן את
            <strong>{{ event.name }}</strong>?

            <br>
            <br>


            <div>

                <div style="width:80%;margin:0 auto;">



                    <label class="form-label">
                        &nbsp;
                        <span style="color:#ab8ce4;cursor:pointer;float:left"
                              @click="$refs.QuickEditPlanupEventStatuses.show()">
                            ניהול מהיר
                        </span>
                    </label>



                    <QuickSuperEdit ref="QuickEditPlanupEventStatuses"
                                    :title="$t('QuickEditPlanupEventStatuses')"
                                    listName="planupEventStatuses"
                                    controller="planup-event-statuses"
                                    :bulkSaveFilterFunction="$handlers.listsHandler.bulkSave_planupEventStatuses"
                                    :fields="$store.state.fieldsForQuickSuperEditPlanupEventStatuses">
                    </QuickSuperEdit>

                    <Multiselect style="width:100%;margin: 0 auto;"
                                 v-model="newStatus"
                                 deselect-label=""
                                 label="name"
                                 :disabled="disableMultiselect"
                                 track-by="id"
                                 :prevent-autofocus="true"
                                 class="whitebg_multiselect"
                                 tagPlaceholder=""
                                 placeholder="סטטוס"
                                 :taggable="true"
                                 @select=""
                                 selectLabel=""
                                 selectedLabel=""
                                 :allow-empty="false"
                                 :showNoOptions="false"
                                 :options="$store.state.planupEventStatuses.filter(status => !status.hidden)">



                        <template #singleLabel="props">


                            <div style="float:left;color:#ff5370"
                                 v-if="props.option.cancelsPnia">

                                <div>
                                    <div style="color:#ff5370"
                                         v-if="hasHazmana">
                                        ההזמנה תבוטל


                                    </div>

                                    <div style="color:#ff5370"
                                         v-else>
                                        הפניה תסומן כלא רלוונטית
                                    </div>

                                </div>
                            </div>
                            <div v-else>
                                <div v-if="event.planupSystemStatus == 'pniaNotRelevant'"
                                     style="float:left;color:green">
                                    הפניה תחזור להיות רלוונטית
                                </div>
                            </div>


                            {{ props.option.name }}

                            <div style="clear:both"></div>

                        </template>



                        <template #option="props">

                            <div style="float:left;color:#ff5370"
                                 v-if="props.option.cancelsPnia">

                                <div>
                                    <div style="color:#ff5370"
                                         v-if="hasHazmana">
                                        ההזמנה תבוטל


                                    </div>

                                    <div style="color:#ff5370"
                                         v-else>
                                        הפניה תסומן כלא רלוונטית
                                    </div>

                                </div>
                            </div>
                            <div v-else>
                                <div v-if="event.planupSystemStatus == 'pniaNotRelevant'"
                                     style="float:left;color:green">
                                    <!-- הפניה תחזור להיות רלוונטית -->
                                </div>
                            </div>


                            {{ props.option.name }}

                            <div style="clear:both"></div>

                        </template>

                    </Multiselect>

                </div>

            </div>


            <br>

            <div style="width:80%;margin:0 auto;"
                 v-if="showCancelations">


                <!-- כדי להשלים את תהליך הביטול, האם תרצו גם: -->



                <div v-if="event.planupSystemStatus == 'hazmanaSent'"
                     style="margin: 5px 0">


                    <label style="user-select: none;cursor:pointer"
                           @click="makeHazmanatBitul = !makeHazmanatBitul">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{ checked: makeHazmanatBitul }">
                            <i class="fa fa-check"></i>
                        </span>

                        המשך להפקת הזמנת ביטול

                        (
                        יצאה הזמנה
                        )


                    </label>

                </div>

                <div v-if="event.planupSystemStatus == 'hazmanaSigned'"
                     style="margin: 5px 0">


                    <label style="user-select: none;cursor:pointer"
                           @click="makeHazmanatBitul = !makeHazmanatBitul">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{ checked: makeHazmanatBitul }">
                            <i class="fa fa-check"></i>
                        </span>

                        המשך להפקת הזמנת ביטול

                        (
                        קיימת הזמנה חתומה
                        )


                    </label>

                </div>


                <div v-if="event.howMuchWasPaid"
                     style="margin: 5px 0">

                    <label style="user-select: none;cursor:pointer"
                           @click="makeNegativeKabala = !makeNegativeKabala">


                        <span style="position:relative;top:1px;"
                              class="planup-checkbox planup-checkbox-purple"
                              :class="{ checked: makeNegativeKabala }">
                            <i class="fa fa-check"></i>
                        </span>


                        המשך
                        להוצאת קבלה שלילית

                        (
                        התקבל תשלום על סך

                        <span style="font-weight: bold;">
                            {{ event.howMuchWasPaid }}
                        </span>
                        &#8362;

                        )




                    </label>







                </div>


            </div>











            <br>
            <br>


            <div style="text-align: left;">
                <button @click="hide"
                        class="btn btn-light">
                    ביטול
                </button>
                &nbsp;
                <button @click="save"
                        class="btn btn-primary">
                    {{ makeHazmanatBitul || makeNegativeKabala ? 'שמור והמשך' : 'שמור' }}
                </button>
            </div>


        </template>
    </Modal>
</template>
<script>
import Multiselect from 'vue-multiselect';
import QuickSuperEdit from '../QuickEdit/QuickSuperEdit.vue';
export default {
    name: 'ChangeEventStatus',
    computed: {

        hasHazmana() {
            return this.event.planupSystemStatus == 'hazmanaSigned' || this.event.planupSystemStatus == 'hazmanaSent';
        },

        showCancelations(){

            if (!this.event.planupEventStatus)
                return this.newStatus?.cancelsPnia;

            return this.newStatus.id != this.event.planupEventStatus.id && this.newStatus.cancelsPnia && !this.event.planupEventStatus.cancelsPnia;
        }

    },
    data() {

        return {
            disableMultiselect: false, //just ui thing that $swal focuses it
            makeHazmanatBitul: false,
            makeNegativeKabala: false,
            customerId:null,
            event: null,
            newStatus: {}
        }

    },
    methods: {
        save() {

            this.disableMultiselect = true;

            if (!this.newStatus.cancelsPnia) {

                if (this.event.planupSystemStatus == 'pniaNotRelevant') {
                    this.$handlers.eventHandler.updateSystemStatus(this.event, 'pniaInTipul',true);
                }

                this.$handlers.eventHandler.updateEventStatus(this.event, this.newStatus);
                this.hide();
                this.disableMultiselect = false;

                return;
            }

            if (this.hasHazmana) {



                this.$swal({
                    title: 'ביטול הזמנה',
                    text: 'ההזמנה לאירוע כבר יצאה - האם אתם בטוחים שאתם רוצים לבטל?',
                    showCancelButton: true,
                    confirmButtonText: 'בטל את ההזמנה',
                    cancelButtonText: 'חזור',
                    confirmButtonColor: '#ff5370'
                }).then(() => {
                    this.disableMultiselect = false;

                    this.$handlers.eventHandler.updateEventStatus(this.event, this.newStatus);
                    this.$handlers.eventHandler.updateSystemStatus(this.event, 'pniaNotRelevant',true);
                    this.hide();


                    this.continueCancelingProcess();



                }).catch(() => {
                    this.disableMultiselect = false;


                });



            } else {


                this.$swal({
                    title: 'ביטול פניה',
                    text: 'האם אתם בטוחים שאתם רוצים לבטל את הפניה?',
                    showCancelButton: true,
                    confirmButtonText: 'בטל את הפניה',
                    cancelButtonText: 'חזור',
                    confirmButtonColor: '#ff5370'
                }).then(() => {

                    this.disableMultiselect = false;

                    this.$handlers.eventHandler.updateEventStatus(this.event, this.newStatus);
                    this.$handlers.eventHandler.updateSystemStatus(this.event, 'pniaNotRelevant',true);
                    this.hide();

                    this.continueCancelingProcess();

                }).catch(() => {

                    this.disableMultiselect = false;

                });


            }

        },

        continueCancelingProcess() {

            if (this.makeHazmanatBitul && this.makeNegativeKabala) {
                this.$router.push(`/createTaxDocument?type=hazmana&customerId=${this.customerId}&eventId=${this.event.id}&cancelOrder&makeNegativeKabala`);
                return;
            }

            if (this.makeHazmanatBitul) {
                this.$router.push(`/createTaxDocument?type=hazmana&customerId=${this.customerId}&eventId=${this.event.id}&cancelOrder`);
                return;
            }

            if (this.makeNegativeKabala) {
                this.$router.push(`/createTaxDocument?type=kabala&customerId=${this.customerId}&eventId=${this.event.id}`);
                return;
            }

        },

        show(event,customerId) {

            this.$refs.modal.show();
            this.customerId = customerId;
            this.event = event;
            this.newStatus = event.planupEventStatus;
        },

        hide() {

            this.$refs.modal.hide();
        }
    },
    watch: {
        newStatus() {
            this.makeNegativeKabala = false;
            this.makeHazmanatBitul = false;
        }
    },
    components: {
        Multiselect, QuickSuperEdit
    }
}
</script>