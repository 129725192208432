<template>
    <div>



        <!-- <h5 :class="{hideExtraFields:hideExtraFields}">
            {{ $globals.titleIdToName(contact.title) }}
        </h5> -->

        <div class="row">

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        שם פרטי
                    </label>
                    <input class="form-control"
                           v-model="contact.firstName">

                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.firstName }}
                    </span>



                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        שם משפחה
                    </label>
                    <input class="form-control"
                           v-model="contact.lastName">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.lastName }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        טלפון
                    </label>
                    <input class="form-control"
                           type="tel"
                           style="direction: ltr;text-align: right;"
                           @input="contact.phone = contact.phone.replace(/(?!^\+)[^0-9]/g, '')"
                           v-model="contact.phone">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.phone }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 v-if="!hideNonEssentialFields">
                <div class="mb-3">
                    <label class="form-label">
                        תפקיד
                        <span style="color:#ab8ce4;cursor:pointer;float:left"
                              @click="$refs.QuickEditContactTitles.show()">
                            ניהול מהיר
                        </span>
                    </label>

                    <QuickEdit controller="customer-contact-titles"
                               ref="QuickEditContactTitles"
                               :title="$t('QuickEditContactTitles')"
                               listName="contactTitles"></QuickEdit>




                    <Multiselect label="name"
                                 track-by="id"
                                 v-model="contact.title"
                                 deselect-label=""
                                 placeholder=""
                                 selectLabel=""
                                 selectedLabel=""
                                 tagPlaceholder="הקישו אנטר ליצירת  תפקיד חדש"
                                 :allow-empty="false"
                                 :showNoOptions="false"
                                 :options="this.$store.state.contactTitles.filter(x => !x.hidden)"
                                 :taggable="true"
                                 @tag="$handlers.listsHandler.createListItem($event, 'contactTitles', 'customer-contact-titles', contact, 'title')">
                    </Multiselect>

                </div>
            </div>


        </div>



        <div class="row"
             :class="{ hideExtraFields: actualHideExtraFields && !(contact.errors && (contact.errors.forceOpenExtraFields)) }">

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        מייל
                    </label>
                    <input class="form-control"
                           type="mail"
                           v-model="contact.email">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.email }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        תעודת זהות / מספר ח.פ.
                    </label>
                    <input class="form-control"
                           type="number"
                           v-model="contact.teudatZehut">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.teudatZehut }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        רחוב
                    </label>
                    <input class="form-control"
                           v-model="contact.address_street">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.address_street }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        מספר בית
                    </label>
                    <input class="form-control"
                           v-model="contact.address_number">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.address_number }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        עיר
                    </label>
                    <input class="form-control"
                           v-model="contact.address_city">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.address_city }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 :class="{ hideNonEssentialFields }">
                <div class="mb-3">
                    <label class="form-label">
                        מיקוד
                    </label>
                    <input class="form-control"
                           type="number"
                           v-model="contact.address_mikud">
                    <span class="formErrorText"
                          v-if="contact.errors">
                        {{ contact.errors.address_mikud }}
                    </span>
                </div>
            </div>

            <div class="col-md-3 col-sm-6"
                 v-if="!hideNonEssentialFields">
                <div class="mb-3">
                    <label class="form-label">
                        הערה אישית
                    </label>
                    <input class="form-control"
                           v-model="contact.comment">
                </div>
            </div>

        </div>





        <div class="pull-right toggleExtraFieldsButton"
             style="cursor:pointer;color:#ab8ce4;"
             v-show="!(contact.errors && contact.errors.forceOpenExtraFields)"
             @click="toggleHideExtraFields()">

            {{ actualHideExtraFields ? 'הצג' : 'הסתר' }}
            שדות נוספים
        </div>


        <div class="pull-right toggleExtraFieldsButton"
             style="color:#aaaaaa;"
             v-show="!hideChangePicButton">
            &nbsp;
            |
            &nbsp;
        </div>



        <div class="pull-right toggleExtraFieldsButton"
             style="cursor:pointer;color:#ab8ce4;"
             @click="$handlers.whatsappHandler.getProfilePic(contact)"
             v-show="!hideChangePicButton">

            עדכן תמונה
        </div>

        <div style="clear:both"></div>



    </div>
</template>

<script>

import QuickEdit from '../QuickEdit/QuickEdit.vue';
import Multiselect from 'vue-multiselect';
import ListsHandler from '../../modules/ListsHandler';

export default {
    name: "CustomerContactFields",
    props: {
        hideChangePicButton: Boolean,
        hideNonEssentialFields: { default: false },
        hideExtraFields: Boolean,
        contact: {
            type: Object
        }
    }
    ,
    methods: {
        toggleHideExtraFields() {
            this.actualHideExtraFields = !this.actualHideExtraFields;
        },
    },
    data() {
        return {
            actualHideExtraFields: this.hideExtraFields, //init from prop
        }
    },
    components: { Multiselect, QuickEdit }
}

</script>

<style>
.hideExtraFields {
    display: none;
}


.hideToggleFields .toggleExtraFieldsButton {
    display: none !important
}


.hideNonEssentialFields {
    width: 33.3333% !important
}
</style>