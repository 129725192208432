import { apiPost, apiRequest } from "../core/http/axios_api_v2";
import axiosV2 from "../core/http/axios_api_v2";

export default {

    async deleteTask(taskToDelete,tasks) {

        let confirmation = !!await planup.$swal({
            title: 'מחיקת משימה',
            text: 'האם למחוק את המשימה ' + '"' + taskToDelete.name + '"' + '?',
            showCancelButton: true,
            confirmButtonText: 'מחק',
            cancelButtonText: 'ביטול',
            confirmButtonColor: '#ff5370',
        }).catch(err => { })

        if (!confirmation)
            return;

        //the ?. operator was not compiling!!!!!!!!!!!!!!!! 
        if (taskToDelete.recurrence && taskToDelete.recurrence['on']){
            await this.handleDeleteRecurrence(taskToDelete);
        } 
        
        apiPost(`tasks/delete/${taskToDelete.id}`);     
        tasks.splice(tasks.indexOf(taskToDelete), 1);

    },

    async handleDeleteRecurrence(task){


        let confirmation = !!await planup.$swal({
            title: 'משימה מחזורית',
            text: 'האם למחוק את שאר המשימות שנוצרו?',
            showCancelButton: true,
            confirmButtonText: 'מחק',
            cancelButtonText: 'ביטול',
            confirmButtonColor: '#ff5370',
        }).catch(err => { })


        if (!confirmation)
            return;

        console.log('deleting recurrence');

    },


    gotoAddNewTaskFromSearch(searchResult, date) {

        if (date) {
            planup.$router.push(`/customer/${searchResult.customerId}?newTask=${date.getTime()}`)
        } else {
            planup.$router.push(`/customer/${searchResult.customerId}?newTask`)
        }

    },



    getEventTasks: (eventId, callback) => {
        axiosV2(`tasks/getEventTasks/${eventId}`).then(response => callback(response.data));
    },



    async saveTask(taskData, hideSuccessAlert) {


        setNoon(taskData.date);


        let clone = shallowCopy(taskData);

        if (taskData.event?.id)
            clone.event = { id: clone.event.id }

        if (taskData.customer?.id)
            clone.customer = { id: clone.customer.id }







        return apiPost('tasks', clone, hideSuccessAlert).then(
            taskDataFromServer => {

                taskData.id = taskDataFromServer.id;
                planup.$handlers.calendarHandler.addItemToCache(taskData.date, 'task', taskData);

            }
        );
    },






    async markTask(id, done) {

        apiPost(`/tasks/mark/${id}?done=${done ? 1 : 0}`)

    },


    toggleTaskStatus(task) {

        task.done = !task.done;
        this.markTask(task.id, task.done);
    },



    async getTask(taskId) {
        return axiosV2(`/tasks/${taskId}`).then(response => response.data);
    },

    async getTasks(limit) {
        return (await axiosV2(`/tasks?limit=${limit}`)).data;
    },


    generateLocalEmptyTask: () => {

        return {
            name: '',
            done: false,
            customer: null,
            event: null,
            date: new Date(),
            showInCalendar: true,
            time: null,
            recurrence: {
                on: false,
                repeatType: window.planup.$store.state.taskRecurrenceRepeatTypes[0],
                repeatCountType: window.planup.$store.state.taskRecurrenceRepeatCountTypes[0],
                endType: null,
                endDate: null,
                days: {
                    sun: null,
                    mon: null,
                    tue: null,
                    wed: null,
                    thu: null,
                    fri: null,
                    sat: null,
                }
            },
        }
    },



    milestonesToTasks(milestones, event, customer) {

        if (!milestones || !milestones.length)
            return;

        let tasks = [];
        milestones.forEach(milestone => {

            let task = this.generateLocalEmptyTask();
            task.event = event;
            task.customer = customer;
            task.name = milestone.name;
            task.description = milestone.details;
            task.crewMembers = milestone.crewMembers;
            task.wasMilestone = true;
            task.priority = milestone.priority;

            if (event.date && milestone.daysBeforeEvent) {

                task.date = new Date(event.date);
                task.date.setDate(event.date.getDate() - milestone.daysBeforeEvent);
            }

            this.saveTask(task, true);
            tasks.push(task);

        })

        this.setEventGotMilestoneTasks(event);

        return tasks;
    },


    setEventGotMilestoneTasks(event) {
        apiPost(`event/setEventGotMilestoneTasks/${event.id}`, null, true);
    },


    calendarTooltip(eventId) {
        return axiosV2.get(`/tasks/tooltip/${eventId}`).then(response => {

            let html = `
            
                <div style="text-align: center;color:rgb(119, 119, 119)">
                    ${planup.$globals.niceDate(response.data.date)}

                    ${response.data.time ? ' &nbsp; ' + response.data.time : ''}

                </div>
                <br>

                <div style="font-weight:bold;">

                    ${response.data.name}

                </div>

                <br>
            
            `;






            for (let i = 0; i < response.data?.customer?.contacts?.length; i++) {

                html += `<img class='eventTooltipContactImage' src="${planup.$handlers.customerHandler.getContactImage(response.data.customer.contacts[i])}"> ${response.data.customer.contacts[i].firstName}&nbsp;&nbsp;`

            }

            html += '<br>';
            html += '<br>';


            if (response.data?.taskType?.name)
                html += 'סוג משימה: ' + response.data.taskType.name + '<br>'


            if (response.data?.crewMembers && response.data.crewMembers.length)
                html += 'צוות : ' + response.data.crewMembers.map(member => member.name).join(', ') + '<br>'


            return html;

        })
    },



}