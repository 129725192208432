<template>
    <div>
        <Modal ref="quickEditModal"
               :title="title"
               :hideFooter="true">
            <template v-slot:body>
                לחצו על פריט כדי לערוך את שמו, או על כפתור המחיקה על מנת להסיר אותו מהרשימה.
                <br>
                <br>
                <div v-for="item in localList"
                     class="quickEditModalItem"
                     :class="{ quickEditModal_noHoverEffect: editingId == item.id }"
                     @click="clickedItem(item, $event)">

                    <input @blur="promptForSaveEditName(item)"
                           v-model="item.name"
                           :disabled="editingId != item.id"
                           :class="{ quickEditModal_inputIsJustText: editingId != item.id, 'form-control': editingId == item.id }">


                    <i @click="deleteItem(item, $event)"
                       class="fa-regular fa-trash-can pull-right"
                       style="color:rgb(119, 119, 119);font-weight:normal !important;position:relative;top:4px;"
                       v-show="editingId != item.id"></i>

                </div>


                <div v-for="newItem in newItems">


                    <input class="form-control quickEditNewItemInput"
                           v-model="newItem.name"
                           placeholder="הזינו כאן את שם הפריט שתרצו להוסיף">

                    <button class="btn btn-primary"
                            @click="saveNewItem(newItem)">
                        הוסף
                    </button>

                </div>


                <span @click="addNewItemToLocalList()"
                      style="color: rgb(141, 99, 218); cursor: pointer; font-size: 25px;position: relative; top: 3px;">
                    <i class="fa-solid fa-plus"></i>
                </span>


            </template>
            <template v-slot:buttons>
                <button @click="save"
                        type="button"
                        class="btn btn-primary"
                        data-bs-dismiss="modal">
                    שמור שינויים
                </button>
            </template>
        </Modal>
    </div>
</template>
<script>
import ListsHandler from '../../modules/ListsHandler';

export default {
    name: "QuickEdit",
    //list is not used, we moved to listName so we can edit the $store directly without vue saying we mutate a prop
    props: ['listName', 'title', 'controller'],
    data() {
        return {
            editingId: null,
            newItems: [],
            localList: [],
        }
    },

    computed: {
        // countItems() - lets us reload our list if the page loads before ajax of loading the $store.state completes
        countItems() {

            return this.$store.state[this.listName].length;
        }
    },

    watch: {
        countItems() {
            this.initList();
        }
    },

    methods: {


        /////////////
        // localList - this local copy exists so that unsaved copies won't propegate all over the system ui
        // initList() - lets us discard unsaved changes when the modal is closed
        // countItems() - lets us reload our list if the page loads before ajax of loading the $store.state completes
        /////////////
        initList() {

            let localList = deepCopy(this.$store.state[this.listName].filter(x => !x.hidden));
            localList.sort((a, b) => a.name.localeCompare(b.name));

            this.localList = localList;

            this.editingId = null;
        },
        /////////////


        promptForSaveEditName(itemInLocalList) {


            let originalItem = this.$store.state[this.listName].find((x) => x.id == itemInLocalList.id);

            if (originalItem.name == itemInLocalList.name) {
                this.editingId = null;
                return;
            }


            let that = this;

            this.$swal({
                title: 'האם לשמור את השינוי?',
                text: 'בכל מקום שהופיע  "' + originalItem.name + '" יופיע מעכשיו "' + itemInLocalList.name + '".',
                showCancelButton: true,
                confirmButtonText: 'שמור',
                cancelButtonText: 'חזור',
                confirmButtonColor: '#ab8ce4'
            }).then((result) => {

                this.updateItemName(itemInLocalList);

            }).catch(error => {

                that.revertLocalNameBackToOriginal(itemInLocalList.id);

            }).finally(x => {
                this.editingId = null;
            })



        },

        revertLocalNameBackToOriginal(id) {
            this.localList.find(x => x.id == id).name = this.$store.state[this.listName].find(x => x.id == id).name;
        },


        updateItemName(item) {

            ListsHandler.updateListItemName(item, this.listName, this.controller)
        },

        clickedItem(item, $event) {

            this.showSaveChangesButton = true;

            this.displayInput = false;

            if (this.editingId == item.id)
                return;

            this.editingId = item.id


            // this is very learned - we are pushing the event to the end of the stack, we let vue set disabled to false
            // and let the browser do what it needs, when our event here is handled which is setting focus. it works!
            //
            //this is actually nextTick
            setTimeout(() => {
                $event.target.querySelector('input').focus()
            }, 0)

        },


        addNewItemToLocalList() {

            this.newItems.push({ name: '' });
        },


        saveNewItem(newItem) {





            if (this.localList.find(i => i.name == newItem.name)) {
                this.$swal({
                    title: `"${newItem.name}" כבר נמצא ברשימה.`,
                    type: "warning",
                    confirmButtonColor: "#ab8ce4",
                    confirmButtonText: 'המשך',
                })
                return;
            }


            ListsHandler.createListItem(newItem.name, this.listName, this.controller)
            this.newItems.splice(this.newItems.indexOf(newItem), 1);

        },


        show() {
            this.$refs.quickEditModal.show();
            this.initList();    //we kill unsaved changes
        },

        deleteItem(item, $event) {

            $event.stopPropagation();//i dont want the click to go up to the text input 

            this.$swal({
                title: 'האם להסיר את האפשרות "' + item.name + '" מהמערכת?',
                html: 'מופעים קיימים לא יפגעו, אך ערך זה לא יופיע יותר כאפשרות לבחירה.',
                showCancelButton: true,
                confirmButtonText: 'הסר',
                cancelButtonText: 'חזור',
                confirmButtonColor: '#ff5370'
            }).then(async (result) => {


                this.localList.splice(this.localList.indexOf(item), 1);
                ListsHandler.deleteItem(this.controller, item.id, this.listName);



            })
                .catch(error => {

                    if (error != 'cancel')
                        console.error(error);
                })



        }
    }
}
</script>
<style>
.quickEditModalItem {
    padding: 10px 5px;
    cursor: pointer;
}

.quickEditModalItem i {
    transform: scale(1.3)
}

.quickEditModalItem:not(.quickEditModal_noHoverEffect):hover,
.quickEditModalItem:not(.quickEditModal_noHoverEffect):hover input,
.quickEditModalItem:not(.quickEditModal_noHoverEffect):hover i {
    /* background-color: #ab8ce4; */
    background-color: rgb(246, 247, 251);
    /* color: white !important; */
    /* font-weight: bold; */
}



.quickEditModalItem i:hover {
    /* transform: scale(1.6) */
    color: #ff5370 !important
}


.quickEditModal_inputIsJustText {
    pointer-events: none;
    background: none;
    border: none;
}

.quickEditNewItemInput {
    margin-bottom: 10px;
    display: inline-block;
    width: calc(100% - 97px);
    margin-left: 10px;
    position: relative;
    top: 2px;
}
</style>